import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosClient from "../../axios-client";

const getAllImages = createAsyncThunk("images", async () => {
  const response = await axiosClient.put(`image/getAllImage`);
  return response.data.data; // Return only the data property
});

const initialState = {
  loading: false,
  error: null,
  imageData: [],
};
const imageSlice = createSlice({
  name: "image",
  initialState,
  reducers: {},
  extraReducers: {
    [getAllImages.pending]: (state) => {
      state.loading = true;
    },
    [getAllImages.fulfilled]: (state, action) => {
      state.loading = false;
      state.imageData = action.payload;
    },
    [getAllImages.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default imageSlice.reducer;

export const imageActions = imageSlice.actions;

export const getImagesData = () => {
  return async (dispatch) => {
    await dispatch(getAllImages());
  };
};
