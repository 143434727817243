// HourlyEventCalendar.js
import React, { useState, useEffect, useCallback } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "moment-timezone";
// import Modal from "react-modal";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "./Calender.css";
import axiosClient from "../../axios-client";
import {
  getAllMessages,
  getAllOrders,
  statusUpdateOrder,
} from "../Header/Data";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaGoogle } from "react-icons/fa";
import EventDetailsModal from "./EventDetailsModal";
import { FaDollarSign } from "react-icons/fa";
import { IoMdSettings } from "react-icons/io";
import { FaChevronCircleRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { FaSyncAlt } from "react-icons/fa";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap";
import { FormControl, MenuItem, Select } from "@mui/material";
import { setOppertunity } from "../../redux/slices/MsgSlice";
import { oppertunityInbox } from "../Header/Data3";
const localTimeZone = "America/New_York";
const localizer = momentLocalizer(moment);

const HourlyEventCalendar = ({ calendarEvents, fetchEvents }) => {
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const [open, setOpen] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [eventsData, setEventsData] = useState([]);
  const [meetingsData, setMeetingsData] = useState([]);
  const [offerData, setOfferData] = useState([]);
  const [view, setView] = useState("month"); // State to track current view
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { oppertunity } = useSelector((state) => state.messages);
  const [oppertunityStage, setOppertunityStage] = useState(false);
  const dispatch = useDispatch();
  const fetchAllMsg = async () => {
    const msgForm = {
      customerId: user.UserID,
      createdBy: user.UserID,
    };
    const messagesData = await getAllMessages(msgForm);
    const filterParent = messagesData.filter((msg) => msg.parentId === 0);
    const myMessages = filterParent.filter(
      (msg) =>
        msg.messageStatus !== 0 &&
        msg.messageStatus !== 1 &&
        msg.messageStatus !== 2
    );
    setOfferData(myMessages);
  };

  useEffect(() => {
    fetchAllMsg();
  }, []);
  console.log(eventsData, "eventsData");
  const events = eventsData.map((event) => ({
    Id: event.service_id,
    title: event.service_need || "Event",
    start: moment(
      `${event.reserve_date} ${event.start_time}`,
      "YYYY-MM-DD HH:mm"
    ).toDate(),
    end: moment(
      `${event.reserve_date} ${event.end_time}`,
      "YYYY-MM-DD HH:mm"
    ).toDate(),
    dec: event.service_need || "Meeting Dec",
    custmorEmail: event.need || "abc@gmail.com",
    orderType: event.orderType,
  }));
  console.log(offerData, "offerData");

  const callStatus = (event) => {
    const dateTime = `${event?.reserveDate} ${event?.startTime}`;

    const providerZone = event?.providerTimezone;
    const customerZone = event?.customerTimezone;

    const proTime = providerZone?.split(",")[0];
    const customerTime = customerZone?.split(",")[0];

    const proZone = providerZone?.split(",")[1];
    const customerZoneName = customerZone?.split(",")[1];

    const timeZoneFormat = (newDate, timeZone1, timeZone2) => {
      let providerTimeLast, customerTimeLast;
      if (event?.zoomRole === event?.createdBy) {
        const providerTime = moment.tz(newDate, timeZone1);
        providerTimeLast = providerTime.format("MMMM D, YYYY [at] hh:mm A");
        const customerTime = moment.tz(providerTime, timeZone2);
        customerTimeLast = customerTime.format("MMMM D, YYYY [at] hh:mm A");
      } else {
        const providerTime = moment.tz(newDate, timeZone2);
        providerTimeLast = providerTime.format("MMMM D, YYYY [at] hh:mm A");
        const customerTime = moment.tz(providerTime, timeZone1);
        customerTimeLast = customerTime.format("MMMM D, YYYY [at] hh:mm A");
      }
      return { providerTimeLast, customerTimeLast };
    };

    const response = timeZoneFormat(dateTime, proTime, customerTime);

    let status = "";

    if (event?.zoomStatus === 0) {
      status = "No Calls Scheduled";
    } else if (event?.zoomStatus === 1) {
      if (event?.zoomRole === user.UserID) {
        status = `Call Request Sent for ${response.providerTimeLast} ${proZone} for 30 mins`;
      } else {
        status = `Call Requested for ${
          user.RoleId !== 1
            ? response.customerTimeLast
            : response.providerTimeLast
        } ${user.RoleId !== 1 ? customerZoneName : proZone} for 30 mins`;
      }
    } else if (event?.zoomStatus === 2) {
      status = "Call rejected";
    } else if (event?.zoomStatus === 3) {
      if (event?.zoomRole === user?.UserID) {
        status = `Call Scheduled for ${response.providerTimeLast} ${
          event?.zoomRole !== event?.createdBy ? customerZoneName : proZone
        } for 30 mins`;
      } else {
        status = `Call Scheduled for ${
          user.RoleId !== 1
            ? response.customerTimeLast
            : response.providerTimeLast
        } ${
          event?.zoomRole !== event?.createdBy ? proZone : customerZoneName
        } for 30 mins`;
      }
    } else if (event?.zoomStatus === 4) {
      if (event?.updatedBy === event?.zoomRole) {
        status = `Rescheduled To ${
          event?.zoomRole === user?.UserID
            ? (user?.UserID === 4 && response.providerTimeLast) ||
              response.providerTimeLast
            : (user?.UserID !== 4 && response.customerTimeLast) ||
              response.customerTimeLast
        } ${
          user && user.UserID === event?.createdBy
            ? proZone
            : user?.UserID === 1
            ? proZone
            : customerZoneName
        } for 30 mins`;
      } else {
        status = `Rescheduled To ${
          event?.zoomRole !== user?.UserID
            ? response.providerTimeLast
            : response.customerTimeLast
        } ${
          user && user.UserID !== 4
            ? proZone
            : user?.UserID === 1
            ? proZone
            : customerZoneName
        } for 30 mins`;
      }
    } else if (event?.zoomStatus === 5) {
      status = "Both or either parties not joined the call";
    } else if (event?.zoomStatus === 6) {
      status = "Call completed";
    } else if (event?.zoomStatus === 7) {
      status = "Call Cancelled";
    }

    // if (event?.messageStatus === 0 || event?.messageStatus === 1) {
    //   if (user.RoleId !== 1) {
    //     status += " - Awaiting Admin Approval";
    //   } else {
    //     status += " - Provider Made An New Offer";
    //   }
    // } else if (event?.messageStatus === 2) {
    //   status += " - Admin Rejected The Offer";
    // } else if (event?.messageStatus === 3 && user.RoleId != 1) {
    //   if (event?.zoomStatus === 3) {
    //     status += " - Awaiting to speak on Call";
    //   } else {
    //     if (event?.ownerId === user.UserID) {
    //       status += " - Provider Made An New Offer";
    //     } else {
    //       status += " - Awaiting Customer Interaction";
    //     }
    //   }
    // } else if (event?.messageStatus === 11) {
    //   if (event?.zoomStatus === 3) {
    //     status += " - Customer viewed offer. Awaiting Interaction";
    //   } else {
    //     if (event?.ownerId === user.UserID) {
    //       status += " - Interact with Provider";
    //     } else {
    //       status += " - Customer viewed offer";
    //     }
    //   }
    // } else if (event?.messageStatus === 4) {
    //   status += " - Customer Accepted Offer";
    // } else if (event?.messageStatus === 9) {
    //   status += " - Provider Accepted The Deal";
    // } else if (event?.messageStatus === 5) {
    //   status += " - Customer Rejected Offer";
    // } else if (event?.messageStatus === 10) {
    //   status += " - Provider Rejected Deal";
    // } else if (
    //   (event?.messageStatus === 6 || event?.messageStatus === 7) &&
    //   user.RoleId !== 1
    // ) {
    //   status += " - Deal In Progress";
    // } else if (
    //   user.RoleId === 1 &&
    //   (event?.messageStatus === 3 ||
    //     event?.messageStatus === 6 ||
    //     event?.messageStatus === 7)
    // ) {
    //   status += " - Deal In Progress";
    // }

    return status;
  };

  const offerEvents = offerData.map((event) => ({
    Id: event?.Id,
    title: event?.postName || "Event",
    start:
      (event?.reserveDate === null || event?.reserveDate === "") &&
      event?.postType === 3
        ? new Date()
        : moment(
            `${event.reserveDate} ${event.startTime}`,
            "YYYY-MM-DDTHH:mm"
          ).toDate(),

    end:
      (event?.reserveDate === null || event?.reserveDate === "") &&
      event?.postType === 3
        ? new Date()
        : moment(`${event.reserveDate} ${event.startTime}`, "YYYY-MM-DDTHH:mm")
            .add(30, "minutes")
            .toDate(),
    custmorEmail: event.customerEmail,
    zoomStatus: event?.zoomStatus,
    zoomRole: event?.zoomRole,
    updatedBy: event?.updatedBy,
    createdBy: event?.createdBy,
    reserveDate: event?.reserveDate,
    startTime: event?.startTime,
    providerTimezone: event?.providerTimezone,
    customerTimezone: event?.customerTimezone,
    meetingLink: event?.meetingLink,
    messageStatus: event?.messageStatus,
    ownerId: event?.ownerId,
    finalBudget: event?.finalBudget,
    dec: event?.postType !== 2 ? callStatus(event) : event?.Message,
    postType: event?.postType,
    customerName: event?.customerName,
    senderName: event?.senderName,
    eventDetails: event?.eventDetails,
    latestType: event?.latestType,
    customerId: event?.customerId,
  }));

  const meetings = meetingsData.map((event) => ({
    Id: event.post_id,
    title: event.service_need || "Meeting",
    start: moment(
      `${event.reserve_date} ${event.start_time}`,
      "YYYY-MM-DD HH:mm"
    ).toDate(),
    end: moment(`${event.reserve_date} ${event.start_time}`, "YYYY-MM-DD HH:mm")
      .add(15, "minutes")
      .toDate(),
    dec: event.service_need || "Meeting Dec",
    custmorEmail: event.need || "abc@gmail.com",
    postType: event.postType,
  }));
  const googleEvents = calendarEvents.map((event) => ({
    Id: event.id, // Assuming Google event ID can be used as Id
    title: event.summary,
    start: moment.utc(event.start.dateTime).tz(localTimeZone).toDate(),
    end: moment.utc(event.end.dateTime).tz(localTimeZone).toDate(),
    dec: event.description,
    custmorEmail: event.organizer.email,
    isGoogleEvent: true,
  }));

  const OppertunityEvents = [
    {
      Id: 1, // Assuming Google event ID can be used as Id
      title: "Lead",
      start: moment("2025-02-10T10:00:00+05:30").toDate(),
      end: moment("2025-02-10T11:00:00+05:30").toDate(),
      dec: "Oppertunity Stage",
      Oppertunity: true,
      Type: 1,
    },
    {
      Id: 2, // Assuming Google event ID can be used as Id
      title: "Invoice Sent",
      start: moment("2025-02-11T10:00:00+05:30").toDate(),
      end: moment("2025-02-11T11:00:00+05:30").toDate(),
      dec: "Oppertunity Stage",
      Oppertunity: true,
      Type: 2,
    },
    {
      Id: 3, // Assuming Google event ID can be used as Id
      title: "Booked",
      start: moment("2025-02-12T10:00:00+05:30").toDate(),
      end: moment("2025-02-12T11:00:00+05:30").toDate(),
      dec: "Oppertunity Stage",
      Oppertunity: true,
      Type: 3,
    },
    {
      Id: 4, // Assuming Google event ID can be used as Id
      title: "Event Completed",
      start: moment("2025-02-13T10:00:00+05:30").toDate(),
      end: moment("2025-02-13T11:00:00+05:30").toDate(),
      dec: "Oppertunity Stage",
      Oppertunity: true,
      Type: 4,
    },
    {
      Id: 5, // Assuming Google event ID can be used as Id
      title: "Cancelled",
      start: moment("2025-02-14T10:00:00+05:30").toDate(),
      end: moment("2025-02-14T11:00:00+05:30").toDate(),
      dec: "Oppertunity Stage",
      Oppertunity: true,
      Type: 5,
    },
  ];

  console.log(meetingsData, "response.data.Data");
  const allEvents = [...offerEvents];
  console.log(googleEvents, "googleEvents");
  console.log(allEvents, "allEvents");
  const fetchData = async () => {
    try {
      const response = await axiosClient.get(
        `order/BlockCalanderDetail/${user.UserID}`
      );
      if (response.data.statusCode === 200) {
        setEventsData(response.data.Data);
        console.log(response.data.Data, "BlockCalanderDetail");
      } else {
        throw new Error(response.data.msg);
      }
    } catch (error) {
      throw new Error("Error fetching Blocked Dates");
    }
    try {
      const response = await axiosClient.get(
        `order/BlockCalanderDetailMsg/${user.UserID}`
      );
      if (response.data.statusCode === 200) {
        setMeetingsData(response.data.Data);
        console.log(response.data.Data, "BlockCalanderDetailMsg");
      } else {
        throw new Error(response.data.msg);
      }
    } catch (error) {
      throw new Error("Error fetching meetings");
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = (event) => {
    // if (event.isGoogleEvent) {
    //   toast.info("google Event cannot be edit");
    //   return;
    // }
    console.log(event, "handleOpen");
    if (!event.Oppertunity) {
      setSelectedEvent(event);
      setOpen(true);
    }
  };
  const reject = async () => {
    const reqData = {
      orderStatus: 2,
      createdBy: user.UserID,
    };
    await statusUpdateOrder(reqData, selectedEvent.Id);
    fetchData();
    setOpen(false);
    toast.warning(`${selectedEvent.title} rejected`);
  };

  const existingEvent = googleEvents.find(
    (event) => event.dec === allEvents[allEvents.length - 2]?.dec
  );

  console.log(existingEvent, "existingEvent");

  const handleGoogle = async () => {
    const client = window.google.accounts.oauth2.initCodeClient({
      client_id: process.env.REACT_APP_SSO_GOOGLE_CLIENT_ID,
      scope: "https://www.googleapis.com/auth/calendar.events",
      //ux_mode: 'popup',
      callback: async (response) => {
        try {
          console.log("====" + response.code);
          if (!response.code) {
            return;
          } else {
            setLoading(true);
            // const existingEvent = googleEvents.find(
            //   (event) => event.dec === allEvents[allEvents.length - 15]?.dec
            // );
            // if (existingEvent) {
            //   console.log("Event already exist");
            //   setLoading(false);
            //   return;
            // }
            // for (const event of allEvents) {
            const form = {
              title: allEvents[allEvents.length - 16]?.title,
              description: allEvents[allEvents.length - 16]?.dec,
              attendees: `${allEvents[allEvents.length - 16]?.custmorEmail},${
                user.Email
              }`,
              startDate:
                allEvents[allEvents.length - 1]?.start !== ""
                  ? moment(allEvents[allEvents.length - 16]?.start).format(
                      "YYYY-MM-DDTHH:mm"
                    )
                  : "",
              endDate:
                allEvents[allEvents.length - 1]?.end !== ""
                  ? moment(allEvents[allEvents.length - 16]?.end).format(
                      "YYYY-MM-DDTHH:mm"
                    )
                  : "",
              timeZones: `${
                allEvents[allEvents.length - 16]?.providerTimezone.split(",")[0]
              }`,
              token: response.code,
            };
            await axiosClient
              .post("auth/createanevent", form)
              .then(function (response) {
                console.log(response);
                setOpen(false);
              })
              .catch(function (error) {
                console.log(error);
                return;
              });
          }
          await fetchAllMsg();
          await fetchData();
          await fetchEvents();
          setLoading(false);
          // }
        } catch (error) {
          console.log("error is ====" + error);
        }
      },
    });
    client.requestCode();
    console.log("Called API", form);
  };
  // }else{
  //   console.error('The object or oauth2 property is undefined');
  // }

  const form = {
    title: selectedEvent?.title,
    description: selectedEvent?.dec,
    attendees: `${selectedEvent?.custmorEmail},${user.Email}`,
    startDate: moment(selectedEvent?.start).format("YYYY-MM-DDTHH:mm"),
    endDate: moment(selectedEvent?.end).format("YYYY-MM-DDTHH:mm"),
    // token: response.code,
  };
  console.log(form, "form");

  // };
  const customWeekHeader = ({ date, label }) => {
    return (
      <div>
        <span>{label}</span>
        <span style={{ marginLeft: "10px", fontSize: "16px" }}>
          {moment(date).format("YYYY")}
        </span>
      </div>
    );
  };
  const eventPropGetter = useCallback(
    (event, start, end, isSelected) => ({
      ...(isSelected && {
        style: {
          backgroundColor: "#000",
        },
      }),
      style: {
        // backgroundColor: event.isGoogleEvent
        //   ? "green"
        //   : event?.postType === 2
        //   ? "#a6a6a6"
        //   : event?.postType === 1 || event?.orderType
        //   ? "#ffff00"
        //   : "#92c7e5", // Assign different colors
        color: "black",
        border: "1px solid #000",
      },
      className: `${
        event?.latestType === 1
          ? "bg-primary"
          : event?.latestType === 2
          ? "bg-warning"
          : event?.latestType === 3
          ? "bg-secondary"
          : event?.latestType === 4
          ? "bg-success"
          : event?.latestType === 5
          ? "bg-danger"
          : "bg-white"
      }`,
    }),
    []
  );
  const handleClick = (e, event) => {
    console.log(event, "HandleClick");
    const offerAmount = event.finalBudget !== null ? event.finalBudget : 0;
    e.stopPropagation();
    if (islogin) {
      navigate("/order/summary", {
        state: {
          event,
          offerAmount,
        },
      });
    } else {
      navigate("/login");
      toast.info("Please Login");
    }
  };
  console.log(loading, "loading");
  const eventDetails = selectedEvent?.eventDetails
    ? JSON.parse(selectedEvent?.eventDetails)
    : [];
  const filteredEvents = eventDetails?.filter(
    (event) =>
      event?.eventDate ||
      event?.eventLocation ||
      event?.eventTime ||
      event?.eventType ||
      event?.totalGuestCount
  );
  console.log(selectedEvent, "selectedEvent");
  const handleChange = (event) => {
    dispatch(setOppertunity(event.target.value));
    setOppertunityStage(true);
  };
  const addOppertunityStage = async () => {
    if (oppertunity && selectedEvent) {
      const formData = {
        messageId: selectedEvent?.Id,
        Type: oppertunity,
        createdBy:
          selectedEvent?.createdBy !== user?.UserID
            ? selectedEvent?.customerId
            : selectedEvent?.createdBy,
        receivedBy:
          selectedEvent?.createdBy === user?.UserID
            ? selectedEvent?.customerId
            : selectedEvent?.createdBy,
      };
      console.log(formData, "CalenderDashboard");
      await oppertunityInbox(formData);
      fetchAllMsg();
      setOppertunityStage(false);
    }
  };
  useEffect(() => {
    if (oppertunityStage) {
      addOppertunityStage();
    }
  }, [oppertunityStage]);
  return (
    <div
      style={{
        textAlign: "center",
        display: "block",
        padding: 30,
        margin: "auto",
      }}
    >
      <h5 className="pb-3">
        View your Bookings <i class="fas fa-sync"></i>
      </h5>
      <p className="pb-3 text-muted text-center ">
        <FaGoogle /> Note: To sync your Google Calendar, click here
        &nbsp;&nbsp;&nbsp;
        <span style={{ color: "blue", cursor: "pointer" }}>
          <FaSyncAlt
            className={`rotate-icon ${loading ? "rotate" : ""}`}
            onClick={handleGoogle}
          />
        </span>
      </p>
      <Calendar
        localizer={localizer}
        events={allEvents}
        startAccessor="start"
        endAccessor="end"
        views={{ day: true, week: true, month: true }}
        step={60}
        timeslots={1}
        defaultView="month"
        defaultDate={moment().toDate()}
        onSelectEvent={handleOpen}
        style={{ height: 650 }}
        showAllEvents
        onView={(newView) => setView(newView)} // Track view change
        components={{
          week: {
            header: customWeekHeader,
          },
          event: ({ event }) => {
            const eventStyle = {
              // backgroundColor: event.isGoogleEvent
              //   ? "green"
              //   : event?.postType === 2
              //   ? "#a6a6a6"
              //   : event?.postType === 1 || event?.orderType
              //   ? "#ffff00"
              //   : "#92c7e5", // Assign different colors
              // backgroundColor:
              //   event?.latestType === 1
              //     ? "#0d6efd"
              //     : event?.latestType === 2
              //     ? "#ffc107"
              //     : event?.latestType === 3
              //     ? "#6c757d"
              //     : event?.latestType === 4
              //     ? "#198754"
              //     : event?.latestType === 5
              //     ? "#dc3545"
              //     : "#ffffff",
              color: "black",
              borderRadius: "0px",
              border: "none",
              cursor: "pointer",
            };

            // Check if current view is 'day' and hide the icons if true
            const hideIconsInDayView = view === "day";
            return (
              <div style={eventStyle}>
                {!hideIconsInDayView && (
                  <div
                    className="d-flex justify-content-between"
                    style={{ color: "black" }}
                  >
                    {(event?.messageStatus === 4 ||
                      event?.messageStatus === 9) &&
                      event?.ownerId === user.UserID &&
                      !event?.isGoogleEvent && (
                        // <button
                        //   className="btn btn-success text-nowrap m-0 px-3"
                        //   onClick={(e) => handleClick(e)}
                        // >
                        //   Proceed To Pay
                        // </button>
                        <FaDollarSign onClick={(e) => handleClick(e, event)} />
                      )}
                    {!event?.isGoogleEvent && (
                      <IoMdSettings
                        onClick={(e) => {
                          e.stopPropagation();
                          setIsOpen(true);
                          setSelectedEvent(event);
                          dispatch(
                            setOppertunity(
                              event?.latestType === null ? 0 : event?.latestType
                            )
                          );
                        }}
                      />
                    )}
                    {!event?.isGoogleEvent && (
                      <FaChevronCircleRight
                        onClick={(e) => {
                          e.stopPropagation();
                          // event?.finalBudget
                          //   ?
                          navigate(`/engagedCouple/${event?.createdBy}`);
                          // : navigate(`/vendordetails/${event?.Id}`);
                        }}
                      />
                    )}
                  </div>
                )}

                <strong>{event.title}</strong>
              </div>
            );
          },
        }}
        eventPropGetter={eventPropGetter}
      />
      <EventDetailsModal
        isOpen={open}
        close={() => {
          setOpen(false);
        }}
        event={selectedEvent}
      />
      <Modal
        isOpen={isOpen}
        toggle={() => {
          setIsOpen(false);
        }}
        size="lg"
      >
        <ModalHeader
          toggle={() => {
            setIsOpen(false);
          }}
        >
          Event Details
        </ModalHeader>
        <ModalBody className="text-center">
          {" "}
          {filteredEvents?.length > 0 ? (
            <Table responsive className="table-light border text-nowrap">
              <thead>
                <tr>
                  <th>S.No.</th>
                  <th>Event Date</th>
                  <th>Event Time</th>
                  <th>Event Type</th>
                  <th>Event Location</th>
                  <th>Guest Count</th>
                </tr>
              </thead>
              <tbody>
                {filteredEvents.map((event, i) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{moment(event.eventDate).format("MMM DD, YYYY")}</td>
                    <td>
                      {moment(event.eventTime, "HH:mm ").format("hh:mm A")}
                    </td>
                    <td>{event.eventType}</td>
                    <td>{event.eventLocation}</td>
                    <td>{event.totalGuestCount}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <div className="text-center my-3">No Events</div>
          )}
          <div className="">
            <span className="fw-bold">Choose Oppertunity Stage:</span> &nbsp;
            <FormControl sx={{ minWidth: 180 }}>
              <Select
                value={oppertunity}
                onChange={handleChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                style={{
                  // fontSize: "13px",
                  backgroundColor: "lightgray",
                }}
              >
                <MenuItem value={0}>
                  <span>Oppertunity Stage</span>
                </MenuItem>
                <MenuItem value={1}>Lead</MenuItem>
                <MenuItem value={2}>Invoice Sent</MenuItem>
                <MenuItem value={3}>Booked</MenuItem>
                <MenuItem value={4}>Event Completed</MenuItem>
                <MenuItem value={5}>Cancelled</MenuItem>
              </Select>
            </FormControl>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              setIsOpen(false);
            }}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default HourlyEventCalendar;
