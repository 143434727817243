import React, { useEffect } from "react";
import { AiFillCaretDown } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import {
  setFinalBwv,
  setSelectedOption,
  setSelectedOptionData,
} from "../../../redux/slices/BwvData";

const BwvOptions = ({ listOptions, optionsData, isReset }) => {
  const { selectedOption, selectedOptionData, originalBwv } = useSelector(
    (state) => state.bwvData
  );
  const dispatch = useDispatch();

  const handleToggleOptions = (listItem) => {
    dispatch(
      setSelectedOption(
        selectedOption?.cat_list_id === listItem.cat_list_id ? null : listItem
      )
    );
  };

  return (
    <div>
      {listOptions &&
        listOptions.map((listItem, index) => (
          <div key={listItem.cat_list_id}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                justifyContent: "start",
                fontSize: "15px",
              }}
              onClick={() => handleToggleOptions(listItem)}
              className="pb-4"
            >
              <strong>{listItem.list_name}</strong>
              <AiFillCaretDown style={{ marginLeft: "5px" }} />
            </div>
            {selectedOption?.cat_list_id === listItem.cat_list_id && (
              <div>
                <ul>
                  {optionsData[listItem.cat_list_id]?.map((option) => (
                    <li
                      key={option.option_id}
                      style={{
                        background:
                          selectedOptionData?.[listItem.cat_list_id] ===
                          option.option_value
                            ? "#967cf3"
                            : "#fff",
                        color:
                          selectedOptionData?.[listItem.cat_list_id] ===
                          option.option_value
                            ? "#fff"
                            : "#000",
                        cursor: "pointer",
                        padding: "10px",
                        borderRadius: "0px 25px",
                      }}
                      onClick={() => dispatch(setSelectedOptionData(option))}
                    >
                      {option.option_name}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        ))}
    </div>
  );
};

export default BwvOptions;
