import React, { useState } from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import TempVideos from "./TempVideos";

const AdminVideos = ({
  highlightedVideos,
  setHighlightedVideos,
  fetchImages,
}) => {
  const [approvedVideosExpanded, setApprovedVideosExpanded] = useState(false);
  const [pendingVideosExpanded, setpendingVideosExpanded] = useState(false);
  let pending =
    highlightedVideos &&
    highlightedVideos.filter((image) => image.image_status === 0);

  let approved =
    highlightedVideos &&
    highlightedVideos.filter((image) => image.image_status === 1);
  return (
    <div className="category-container d-flex flex-column gap-2 inter-font">
      <div
        className="category-header"
        onClick={() => setApprovedVideosExpanded(!approvedVideosExpanded)}
      >
        <h6
          className={`ps-3 d-flex justify-content-between p-3 rounded border border-secondary text-secondary bg-outline-secondary ${
            approvedVideosExpanded ? "active text-white bg-secondary" : ""
          }`}
          style={{ cursor: "pointer", transition: "all 0.2s ease-in-out" }}
        >
          Approved Videos
          {approvedVideosExpanded ? (
            <FaAngleUp size={20} />
          ) : (
            <FaAngleDown size={20} />
          )}
        </h6>
      </div>
      {approvedVideosExpanded && (
        <>
          {approved.length > 0 ? (
            <TempVideos
              highlightedVideos={approved}
              setHighlightedVideos={setHighlightedVideos}
              fetchImages={fetchImages}
            />
          ) : (
            <h6 className="text-center m-5">No Approved Videos</h6>
          )}
        </>
      )}

      <div
        className="category-header"
        onClick={() => setpendingVideosExpanded(!pendingVideosExpanded)}
      >
        <h6
          className={`ps-3 d-flex justify-content-between p-3 rounded border border-secondary text-secondary bg-outline-secondary ${
            pendingVideosExpanded ? "active text-white bg-secondary" : ""
          }`}
          style={{ cursor: "pointer", transition: "all 0.2s ease-in-out" }}
        >
          Pending Videos
          {pendingVideosExpanded ? <FaAngleUp /> : <FaAngleDown />}
        </h6>
      </div>
      {pendingVideosExpanded && (
        <>
          {pending.length > 0 ? (
            <TempVideos
              highlightedVideos={pending}
              setHighlightedVideos={setHighlightedVideos}
              fetchImages={fetchImages}
            />
          ) : (
            <h6 className="text-center m-5">No Pending Videos</h6>
          )}
        </>
      )}
    </div>
  );
};

export default AdminVideos;
