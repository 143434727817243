/* eslint-disable no-unused-vars */
import React, { useState, useEffect, Suspense } from "react";
import {
  Row,
  Col,
  Button,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import SpAds from "./spads";
import { FaTh, FaList, FaSlidersH } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import getBid from "../images/get-bids.png";
import { addFavourite } from "../Data";
import { useDispatch, useSelector } from "react-redux";
import SortVendors from "./SortVendors";
import "../../../pages/DashboardPages/DashboardPages.css";
import { FadeLoader } from "react-spinners";
import BwvList from "./BwvList";
import BwvGrid from "./BwvGrid";
import { listFavourite } from "../Data";
import { setLikedCards } from "../../../redux/slices/BwvData";

const BwvContent = ({ handleclear, isLoading }) => {
  const { finalBwv, finalNeeds, searchQuery } = useSelector(
    (state) => state.bwvData
  );
  const navigate = useNavigate();
  const islogin = useSelector((state) => state.user.isLogin);
  const [viewMode, setViewMode] = useState("grid");
  const cardsPerPage = 9;
  const [currentPage, setCurrentPage] = useState(1);
  // const [likedCards, setLikedCards] = useState({});
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const dispatch = useDispatch();
  const likedCards = useSelector((state) => state.bwvData.likedCards);

  const tgl_filter = () => {
    var myele = document.getElementById("bwv-sidebar");
    myele.classList.remove("bwvfilter-close");
    myele.classList.add("bwvfilter-show");
  };

  const handlePagination = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const currentCards = finalBwv.slice(
    (currentPage - 1) * cardsPerPage,
    currentPage * cardsPerPage
  );
  const currentNeeds = finalNeeds.slice(
    (currentPage - 1) * cardsPerPage,
    currentPage * cardsPerPage
  );
  useEffect(() => {
    if (user) {
      const fetchData = async () => {
        const response = await listFavourite(user?.UserID);
        const likedServiceIds = response.map((item) => item.serviceId);
        const likes = {
          ...likedCards,
          ...likedServiceIds.reduce(
            (acc, serviceId) => ({ ...acc, [serviceId]: true }),
            {}
          ),
        };

        dispatch(setLikedCards(likes));
      };
      fetchData();
    }
  }, [finalBwv, user?.UserID]);
  console.log(currentNeeds, "currentNeeds");
  console.log(finalNeeds, "currentNeeds-finalNeeds");
  return (
    <div>
      <SpAds />
      <Row className="">
        <div>
          <span
            onClick={tgl_filter}
            className="mbbwv-filter-btn"
            id="mbbwv-filter-btn"
          >
            <FaSlidersH /> Filters
          </span>
        </div>
      </Row>
      <Row
        style={{
          display: "flex",
          alignItems: "center",
        }}
        // className="p-lg-4"
      >
        <Col
          className="m-2"
          style={{ display: "flex", alignItems: "center", gap: 8 }}
        >
          <span className="show-results inter-font">
            {finalBwv.length + finalNeeds.length} results
          </span>
          <div
            style={{
              color: "red",
              backgroundColor: "#FCEFEF",
              borderRadius: "10px",
              padding: "6px 12px",
              cursor: "pointer",

              fontSize: "13px",
              fontWeight: "bold",
            }}
            className="show-results inter-font py-2"
            onClick={handleclear}
          >
            Clear Results
          </div>
        </Col>
        <Col className="m-2">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              gap: 8,
            }}
            className="Bwvshort"
          >
            <div>
              <SortVendors />
            </div>
            <div
              style={{
                padding: "2.5%",
                border: "1px solid gray",
                borderRadius: "5px",
                width: "80px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                cursor: "pointer",
              }}
            >
              <FaTh onClick={() => setViewMode("grid")} />
              <FaList onClick={() => setViewMode("list")} />
            </div>
          </div>
        </Col>
      </Row>

      <div className={`position-relative ${isLoading && "m-5 p-5"}`}>
        {isLoading ? (
          <FadeLoader // FadeLoader component
            css={{ margin: "0 auto" }}
            color={"#36D7B7"}
            loading={isLoading}
            className="position-absolute top-50 start-50 translate-middle"
          />
        ) : (
          <>
            {viewMode === "grid" && (
              <BwvGrid
                currentCards={currentCards}
                currentNeeds={currentNeeds}
              />
            )}
          </>
        )}
        {viewMode === "list" && (
          <BwvList currentCards={currentCards} currentNeeds={currentNeeds} />
        )}
      </div>

      <Pagination className=" d-flex justify-content-center align-items-center mt-3">
        <PaginationItem disabled={currentPage === 1}>
          <PaginationLink
            previous
            onClick={() => handlePagination(currentPage - 1)}
          />
        </PaginationItem>
        {Array.from({
          length: Math.ceil(finalBwv.length / cardsPerPage),
        }).map((_, index) => (
          <PaginationItem key={index} active={index + 1 === currentPage}>
            <PaginationLink onClick={() => handlePagination(index + 1)}>
              {index + 1}
            </PaginationLink>
          </PaginationItem>
        ))}
        <PaginationItem
          disabled={currentPage === Math.ceil(finalBwv.length / cardsPerPage)}
        >
          <PaginationLink
            next
            onClick={() => handlePagination(currentPage + 1)}
          />
        </PaginationItem>
      </Pagination>
    </div>
  );
};

export default BwvContent;
