import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const initialState = {
  originalBwv: [],
  originalNeeds: [],
  finalBwv: [],
  finalNeeds: [],
  selectedService: null,
  likedCards: [],
  listOptions: [],
  allServices: [],
  myServices: [],
  activeLink: "",
  device: null,
  isReviewModalOpen: false,
  isReportingModalOpen: false,
  isInvite: false,
  endorStatus: [],
  loadingEndorse: false,
  linkModal: false,
  isModalOpen: false,
  quoteModal: false,
  quotePop: null,
  selectedServiceDate: new Date(),
  selectedPackageRate: null,
  bwvSearchQuery: "",
  searchTerm: "",
  selectedQuary: null,
  selectedOption: null,
  selectedOptionData: null,
  selectedCat: null,
};

const BwvDataSlice = createSlice({
  name: "serviceDetails",
  initialState,
  reducers: {
    resetVendorDetailsState: (state) => initialState,
    setOriginalBwv: (state, action) => {
      state.originalBwv = action.payload;
    },
    setOriginalNeeds: (state, action) => {
      state.originalNeeds = action.payload;
    },
    setFinalBwv: (state, action) => {
      state.finalBwv = action.payload;
    },
    setFinalNeeds: (state, action) => {
      state.finalNeeds = action.payload;
    },
    setSelectedService: (state, action) => {
      state.selectedService = action.payload;
    },
    setLikedCards: (state, action) => {
      state.likedCards = action.payload;
    },
    setListOptions: (state, action) => {
      state.listOptions = action.payload;
    },
    setAllServices: (state, action) => {
      state.allServices = action.payload;
    },

    setMyServices: (state, action) => {
      state.myServices = action.payload;
    },
    setActiveLink: (state, action) => {
      state.activeLink = action.payload;
    },
    setDevice: (state, action) => {
      state.device = action.payload;
    },
    setIsReviewModalOpen: (state, action) => {
      state.isReviewModalOpen = action.payload;
    },
    setIsReportingModalOpen: (state, action) => {
      state.isReportingModalOpen = action.payload;
    },
    setIsInvite: (state, action) => {
      state.isInvite = action.payload;
    },
    setEndorStatus: (state, action) => {
      state.endorStatus = action.payload;
    },
    setLoadingEndorse: (state, action) => {
      state.loadingEndorse = action.payload;
    },
    setLinkModal: (state, action) => {
      state.linkModal = action.payload;
    },
    setIsModalOpen: (state, action) => {
      state.isModalOpen = action.payload;
    },
    setQuoteModal: (state, action) => {
      state.quoteModal = action.payload;
    },
    setQuotePop: (state, action) => {
      state.quotePop = action.payload;
    },
    setSelectedServiceDate: (state, action) => {
      state.selectedServiceDate = action.payload;
    },

    setSelectedPackageRate: (state, action) => {
      state.selectedPackageRate = action.payload;
    },
    setBwvSearchQuery: (state, action) => {
      state.bwvSearchQuery = action.payload;
    },
    setSearchTerm: (state, action) => {
      state.searchTerm = action.payload;
    },
    setSelectedQuary: (state, action) => {
      state.selectedQuary = action.payload;
    },
    setSelectedOption: (state, action) => {
      state.selectedOption = action.payload;
    },
    setSelectedOptionData: (state, action) => {
      state.selectedOptionData = action.payload;
    },
    setSelectedCat(state, action) {
      state.selectedCat = action.payload;
    },
  },
});

export const {
  resetVendorDetailsState,
  setOriginalBwv,
  setOriginalNeeds,
  setFinalBwv,
  setFinalNeeds,
  setLikedCards,
  setSelectedService,
  setListOptions,
  setAllServices,
  setMyServices,
  setActiveLink,
  setDevice,
  setIsReviewModalOpen,
  setIsReportingModalOpen,
  setIsInvite,
  setEndorStatus,
  setLoadingEndorse,
  setLinkModal,
  setIsModalOpen,
  setQuoteModal,
  setQuotePop,
  setSelectedServiceDate,
  setSelectedPackageRate,
  setBwvSearchQuery,
  setSearchTerm,
  setSelectedQuary,
  setSelectedOption,
  setSelectedOptionData,
  setSelectedCat,
} = BwvDataSlice.actions;
export default BwvDataSlice.reducer;
