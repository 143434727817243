import React, { useEffect, useState } from "react";
import ReviewCards from "./ReviewCards";
import { getAllReviews } from "../../../components/Header/Data";
import { FadeLoader } from "react-spinners"; // Import FadeLoader

const ReviewMessages = () => {
  const [reviews, setReviews] = useState([]);
  const [isInitialLoading, setIsInitialLoading] = useState(true); // Only for first load

  const fetchData = async () => {
    try {
      const allReviews = await getAllReviews();
      setReviews(allReviews);
    } catch (error) {
      console.error("Error fetching reviews:", error);
    } finally {
      setIsInitialLoading(false); // Stop loading only after the first fetch
    }
  };

  useEffect(() => {
    fetchData();
  }, []); // Runs only on initial load

  return (
    <div>
      {isInitialLoading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "150px",
          }}
        >
          <FadeLoader color="#36d7b7" />
        </div>
      ) : (
        <ReviewCards reviews={reviews} fetchData={fetchData} />
      )}
    </div>
  );
};

export default ReviewMessages;
