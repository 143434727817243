import React, { useEffect, useState } from "react";
import SingleImg from "./SingleImg";
import {
  AddImgTemplate,
  GetAllMediaTemplates,
  //   UpdatePosition,
} from "../../../../components/Header/Data3";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "reactstrap";
import { FaSave } from "react-icons/fa";

const TempImages = ({
  highlightedImages,
  setHighlightedImages,
  fetchImages,
}) => {
  const dispatch = useDispatch();
  const isLogin = useSelector((state) => state.user.isLogin);
  const userInfo = useSelector((state) => state.user.userInfo);
  const user = isLogin ? JSON.parse(userInfo) : null;
  // Initially empty
  const isAdmin = user && user.Role === "admin"; // Check if user is admin
  return (
    <div>
      <div className="category-container d-flex flex-wrap gap-3 inter-font">
        {highlightedImages.map((imageObj, index) => (
          <SingleImg
            key={imageObj?.id || index} // Use `id` as key if available
            image={imageObj}
            index={index} // Pass `index` for drag-drop logic
            fetchImages={fetchImages}
            highlightedImages={highlightedImages}
            setHighlightedImages={setHighlightedImages} // Ensure state updates
          />
        ))}
      </div>
    </div>
  );
};

export default TempImages;
