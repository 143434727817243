import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  allUsers: [],
  loadingUsers: false,
  finalUsers: [],
  extRequestFilter: false,
};
const userSates = createSlice({
  name: "adminData",
  initialState,
  reducers: {
    setAllUsers: (state, action) => {
      state.allUsers = action.payload;
    },
    setLoadingUsers: (state, action) => {
      state.loadingUsers = action.payload;
    },
    setFinalUsers: (state, action) => {
      state.finalUsers = action.payload;
    },
    setExtRequestFilter: (state, action) => {
      state.extRequestFilter = action.payload;
    },
  },
});

export const {
  setAllUsers,
  setLoadingUsers,
  setFinalUsers,
  setExtRequestFilter,
} = userSates.actions;
export default userSates.reducer;
