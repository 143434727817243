import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import { toast } from "react-toastify";
import { listGurudwaras, updateProfile } from "../../components/Header/Data";
import { countryCodes } from "../../components/CountryCodes";
import { Col, Row } from "reactstrap";

const UpdateUserModal = ({ isOpen, closeModal, userInfo }) => {
  const [newDetails, setNewDetails] = useState({
    FirstName: userInfo?.FirstName || "",
    LastName: userInfo?.LastName || "",
    UserName: userInfo?.UserName || "",
    Email: userInfo?.Email || "",
    Phone: userInfo?.MobileNo || "",
    Gurudwara: userInfo?.Gurudwara || "",
    twilioNumber: userInfo?.twilioNumber || "",
    secondaryPhone: userInfo?.secondaryPhone || "",
    dialExt: userInfo?.dialExt || "",
  });
  const [gurdwaras, setGurdwaras] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("+1");

  useEffect(() => {
    const fetchData = async () => {
      const gurudwaras = await listGurudwaras();
      setGurdwaras(gurudwaras);
    };
    fetchData();
  }, []);
  console.log(countryCodes, "countryCodes");
  useEffect(() => {
    setNewDetails({
      FirstName: userInfo?.FirstName || "",
      LastName: userInfo?.LastName || "",
      UserName: userInfo?.UserName || "",
      Email: userInfo?.Email || "",
      Phone: userInfo?.MobileNo || "",
      Gurudwara: userInfo?.Gurudwara || "",
      twilioNumber: userInfo?.twilioNumber || "",
      secondaryPhone: userInfo?.secondaryPhone || "",
      dialExt: userInfo?.dialExt || "",
    });
  }, [userInfo]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewDetails({ ...newDetails, [name]: value });
  };

  const handleSubmit = async () => {
    if (
      !newDetails.FirstName ||
      !newDetails.LastName ||
      !newDetails.Email ||
      !newDetails.Phone
    ) {
      toast.error("Please fill all required fields");
      return;
    }
    try {
      await updateProfile(newDetails, userInfo.Id);
      toast.success(`${newDetails.UserName} Profile Updated`);
      closeModal();
    } catch (error) {
      console.error(error);
      toast.error(error.message);
    }
  };

  return (
    <Dialog open={isOpen} onClose={closeModal} fullWidth>
      <DialogTitle>Update User</DialogTitle>
      <DialogContent>
        <Row>
          <Col sm={12} md={6}>
            <TextField
              label="First Name"
              name="FirstName"
              value={newDetails.FirstName}
              onChange={handleInputChange}
              fullWidth
              margin="dense"
              required
            />
          </Col>
          <Col sm={12} md={6}>
            <TextField
              label="Last Name"
              name="LastName"
              value={newDetails.LastName}
              onChange={handleInputChange}
              fullWidth
              margin="dense"
              required
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={6}>
            <TextField
              label="User Name"
              name="UserName"
              value={newDetails.UserName}
              onChange={handleInputChange}
              fullWidth
              margin="dense"
            />
          </Col>
          <Col sm={12} md={6}>
            <TextField
              label="Email"
              name="Email"
              value={newDetails.Email}
              onChange={handleInputChange}
              fullWidth
              margin="dense"
              required
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={5}>
            <TextField
              label="Mobile"
              name="Phone"
              value={newDetails.Phone}
              onChange={handleInputChange}
              fullWidth
              margin="dense"
              required
            />
          </Col>
          <Col sm={12} md={5}>
            <TextField
              label="Secondary Mobile"
              name="secondaryPhone"
              value={newDetails.secondaryPhone}
              onChange={handleInputChange}
              fullWidth
              margin="dense"
            />
          </Col>
          <Col sm={12} md={2}>
            <TextField
              label="Dial Ext"
              name="dialExt"
              value={newDetails.dialExt}
              onChange={handleInputChange}
              fullWidth
              margin="dense"
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={12}>
            <FormControl fullWidth margin="dense">
              <InputLabel>Select Gurdwara</InputLabel>
              <Select
                name="Gurudwara"
                value={newDetails.Gurudwara}
                onChange={handleInputChange}
              >
                <MenuItem value="0">Select a Gurdwara</MenuItem>
                {gurdwaras.map((gurudwara) => (
                  <MenuItem key={gurudwara.Id} value={gurudwara.Id}>
                    {gurudwara.Title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={2}>
            {/* <Select
                value={selectedCountry}
                // onChange={(e) => setSelectedCountry(e.target.value)}
                disabled
              >
                {countryCodes.map((code) => (
                  <MenuItem key={code.dial_code} value={code.dial_code}>
                    {code.dial_code}
                  </MenuItem>
                ))}
              </Select> */}
            <TextField
              label="Country Code"
              name="countryCode"
              value={selectedCountry}
              onChange={handleInputChange}
              fullWidth
              margin="dense"
              disabled
            />
          </Col>
          <Col sm={12} md={10}>
            <TextField
              label="Twilio Number"
              name="twilioNumber"
              value={newDetails.twilioNumber}
              onChange={handleInputChange}
              fullWidth
              margin="dense"
            />
          </Col>
        </Row>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateUserModal;
