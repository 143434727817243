import React from "react";
import BwvCards from "../BwvCards";
import LcaBox from "../LcaBox";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ChatModal from "../../modals/ChatModal";
import {
  setMessages,
  setSelectedConversation,
} from "../../../../redux/slices/MsgSlice";
import {
  setIsMsgModalOpen,
  setSelectedAd,
} from "../../../../redux/slices/LcaSlice";

const VendorCards = ({ allProducts, carouselData }) => {
  const navigate = useNavigate();
  const islogin = useSelector((state) => state.user.isLogin);
  const userdata = useSelector((state) => state.user);
  const userinfo = useSelector((state) => state.user.userInfo);
  const dispatch = useDispatch();
  const user = islogin ? JSON.parse(userinfo) : null;
  const { Lca, selectedAd, isMsgModalOpen } = useSelector(
    (state) => state.lcAds
  );
  let upcomingProducts = Lca.filter(
    (product) =>
      product.engagedCouple == 0 &&
      product.categoryId === 1 &&
      product.subCategoryId === 63
  );
  const verifiedLcaBox = upcomingProducts.filter((ad) => ad.caIdStatus === 1);
  const unverifiedLcaBox = upcomingProducts.filter((ad) => ad.caIdStatus === 0);
  const closeModal = () => {
    dispatch(setSelectedConversation(null));
    dispatch(setIsMsgModalOpen(false));
    dispatch(setSelectedAd(null));
    dispatch(setMessages(null));
  };
  // Separate BwvCards items into verified and unverified

  return (
    <>
      {verifiedLcaBox &&
        verifiedLcaBox.map((ad, index) => (
          <div key={ad.Id} style={{ cursor: "pointer" }}>
            <p
              className="mb-1"
              style={{ fontSize: "medium" }}
              onClick={() => {
                islogin
                  ? navigate(`/userDetails/${ad.createdBy}`)
                  : toast.info("Please login to view profile");
                console.log(ad, "userDetails");
              }}
            >
              {ad?.FullName}
            </p>
            <LcaBox ad={ad} />
          </div>
        ))}
      <BwvCards allProducts={allProducts} carouselData={carouselData} />
      {unverifiedLcaBox.map((ad) => (
        <div key={ad.Id} style={{ cursor: "pointer" }}>
          <p
            className="mb-1"
            style={{ fontSize: "medium" }}
            onClick={() => {
              islogin
                ? navigate(`/userDetails/${ad.createdBy}`)
                : toast.info("Please login to view profile");
            }}
          >
            {ad?.FullName} (Unverified)
          </p>
          <LcaBox ad={ad} />
        </div>
      ))}
      <ChatModal
        isOpen={isMsgModalOpen}
        closeModal={closeModal}
        ad={selectedAd}
      />
    </>
  );
};

export default VendorCards;
