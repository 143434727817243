import { configureStore } from "@reduxjs/toolkit";
import cartSlice from "./slices/cartSlice";
import userSlice from "./slices/userSlice";
import settingSlice from "./slices/settingSlice";
import bwvSlice, { getBwvdata } from "./slices/bwvSlice";
import imageSlice, { getImagesData } from "./slices/ImageSlice";
import GiftSlice from "./slices/GiftSlice";
import FreeServiceSlice from "./slices/FreeServiceSlice";
import RequestFormSlice from "./slices/RequestFormSlice";
import ServiceInfoSlice from "./slices/ServiceInfo";
import PaymentSlice from "./slices/PaymentSlice";
import BwvDataSlice from "./slices/BwvData";
import localCommunityAdsSlice from "./slices/LcaSlice";
import MyNeedsSlice from "./slices/MyNeedsSlice";
import MessagesSlice from "./slices/MsgSlice";
import IteamFormSlice from "./slices/IteamFormSlice";
import peopleInCom from "./slices/PeopleInComSlice";
import gurdwaraSlice from "./slices/GurdwaraSlice";
import TemplateSlice from "./slices/TemplateSlice";
import donationReducer from "./slices/DonationSlice";
import latestStates from "./slices/LatestAdsSlice";
import ItemsDataSlice from "./slices/ItemsDataSlice";
import socketSlice from "./slices/SocketSlice";
import userSates from "./slices/UserStateSlice";

const store = configureStore({
  reducer: {
    cart: cartSlice,
    user: userSlice,
    setting: settingSlice,
    bwv: bwvSlice,
    images: imageSlice,
    gift: GiftSlice,
    freeService: FreeServiceSlice,
    requestForm: RequestFormSlice,
    serviceInfo: ServiceInfoSlice,
    payment: PaymentSlice,
    bwvData: BwvDataSlice,
    lcAds: localCommunityAdsSlice,
    myNeeds: MyNeedsSlice,
    messages: MessagesSlice,
    IteamForm: IteamFormSlice,
    peopleInCom: peopleInCom,
    gurdwara: gurdwaraSlice,
    templates: TemplateSlice,
    gurdwaraDonation: donationReducer,
    latestState: latestStates,
    ItemsData: ItemsDataSlice,
    socket: socketSlice,
    userState: userSates,
  },
});

store.dispatch(getImagesData());

export default store;
