import React, { useState } from "react";
import { FormGroup, Input, Spinner } from "reactstrap"; // Ensure Reactstrap is installed
import { FaPlusCircle } from "react-icons/fa"; // Ensure react-icons is installed
import uploadToS3 from "../../../../utils/awsS3Upload";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import {
  AddImgTemplate,
  UpdateImagePosition,
  UpdateImageStatus,
} from "../../../../components/Header/Data3";
import ImagePreviewModal from "./ImagePreviewModal";

const SingleVid = ({ video, index, fetchVideos, highlightedVideos }) => {
  const isLogin = useSelector((state) => state.user.isLogin);
  const userInfo = useSelector((state) => state.user.userInfo);
  const user = isLogin ? JSON.parse(userInfo) : null;
  const isAdmin = user && user.Role === "admin";
  // Store the full image object instead of just the image URL
  const [videoObject, setVideoObject] = useState(video || null);
  const [modalOpen, setModalOpen] = useState(false); // State for modal
  const [fileSending, setFileSending] = useState(false);
  console.log(highlightedVideos, "highlightedVideos");
  const handleFileChange = async (event) => {
    setFileSending(true);
    const files = event.target.files;
    if (files.length > 0) {
      const videoFile = files[0];
      if (videoFile) {
        try {
          const folder = "mediaTemp/";
          const uploadedVideoUrl = await uploadToS3(videoFile, folder); // Upload to S3 and get URL
          const newVideo = {
            imageUrl: uploadedVideoUrl,
            imageTitle: `video Title ${index + 1}`,
            imageDescription: `Description for video ${index + 1}`,
            imagePosition: index,
          };
          const payload = {
            imageStatus: 0,
            imageDetails: JSON.stringify(newVideo),
            imageType: 1,
            userName: user?.UserName,
            createdBy: user?.UserID,
          };

          try {
            const response = await AddImgTemplate(payload);
            console.log("video saved successfully:", response);
          } catch (error) {
            console.error("Error saving video:", error);
          }

          setVideoObject(newVideo); // Store full image object locally
        } catch (error) {
          console.error("Error uploading video to S3:", error);
          toast.error("video upload failed. Please try again.");
        }
      }
    }
    fetchVideos();
    setFileSending(false);
  };

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const handleStatus = async (status) => {
    const request = {
      imageStatus: status,
      updatedBy: user?.UserID,
      createdBy: user?.UserID,
    };
    await UpdateImageStatus(videoObject.id, request);
    if (status == 2) {
      // Remove the image from state and notify parent component
      setVideoObject(null); // Clear the image object locally
      setModalOpen(false); // Close the modal
      toast.success("video deleted successfully.");
    } else {
      setVideoObject((prevImage) => ({ ...prevImage, image_status: status }));
      fetchVideos();
      setModalOpen(false); // Close the modal
    }
    fetchVideos();
  };
  const ImageStatus = () => {
    const getStatusStyle = (status) => {
      switch (status) {
        case 0:
          return "status-pending"; // Yellow for pending
        case 1:
          return "status-approved"; // Green for approved
        case 2:
          return "status-deleted"; // Red for deleted
        case 3:
          return "status-rejected"; // Red for rejected
        default:
          return "";
      }
    };
    return (
      <div
        className={`image-status ${getStatusStyle(videoObject.image_status)}`}
      >
        <p>
          {videoObject.image_status === 0
            ? "PENDING"
            : videoObject.image_status === 1
            ? "APPROVED"
            : "REJECTED"}
        </p>
      </div>
    );
  };
  const handleDragStart = (index, event) => {
    event.dataTransfer.setData("dragIndex", index.toString()); // Store the drag index
  };

  const handleDrop = async (index, event) => {
    event.preventDefault();

    const dragIndex = parseInt(event.dataTransfer.getData("dragIndex"), 10); // Get the drag index

    if (
      dragIndex === index || // No need to update if dropped on the same position
      dragIndex < 0 || // Invalid index
      dragIndex >= highlightedVideos.length
    ) {
      return;
    }

    // Reorder images locally
    const updatedImages = [...highlightedVideos];
    const [draggedImage] = updatedImages.splice(dragIndex, 1); // Remove dragged image
    updatedImages.splice(index, 0, draggedImage); // Insert at the new position

    const updatedImageData = updatedImages.map((image, idx) => ({
      id: image.id, // Assuming image has an `id` field
      position: idx, // New position based on index
    }));
    await UpdateImagePosition(updatedImageData);
    fetchVideos();
  };
  console.log(highlightedVideos, "highlightedVideos");
  return (
    <div className="">
      {!isAdmin && index < 2 && (
        <div className="preferred-title">Preferred</div>
      )}
      <FormGroup style={{ padding: "10px 0px" }}>
        <Input
          type="file"
          id={`videoUpload${index}`}
          onChange={handleFileChange}
          style={{ display: "none" }}
          accept="video/*"
        />
      </FormGroup>
      {/* Image or Upload Icon */}
      <div
        className={
          !isAdmin && index < 2
            ? "highlighted temp-image-box"
            : "temp-image-box"
        }
        onClick={() => {
          if (videoObject) {
            toggleModal(); // If image exists, open modal
          } else {
            document.getElementById(`videoUpload${index}`).click(); // Otherwise, trigger file input
          }
        }}
        onDragStart={(e) => !isAdmin && handleDragStart(index, e)} // Don't trigger dragStart for admins
        onDragOver={(e) => !isAdmin && e.preventDefault()} // Prevent drop for admins
        onDrop={(e) => !isAdmin && handleDrop(index, e)} // Don't allow drop for admins
        draggable={!isAdmin} // Make image non-draggable for admins
      >
        {fileSending ? (
          <Spinner color="primary" /> // Show a loading spinner while uploading
        ) : videoObject ? (
          <video
            src={videoObject.imageUrl}
            controls
            className="uploaded-image image-fluid rounded"
            style={{
              width: "280px",
              height: "170px",
              objectFit: "contain",
              margin: "auto",
              display: "block",
            }}
          />
        ) : (
          <FaPlusCircle className="upload-icon" style={{ fontSize: "24px" }} />
        )}
      </div>
      {videoObject && <ImageStatus />}
      {videoObject && (
        <ImagePreviewModal
          modalOpen={modalOpen}
          toggleModal={toggleModal}
          imageUrl={videoObject}
          index={index}
          handleStatus={handleStatus}
        />
      )}
    </div>
  );
};

export default SingleVid;
