import React, { useEffect, useState } from "react";
import {
  AddImgTemplate,
  GetAllMediaTemplates,
  //   UpdatePosition,
} from "../../../../components/Header/Data3";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "reactstrap";
import { FaSave } from "react-icons/fa";
import SingleVid from "./SingleVid";

const TempVideos = ({
  highlightedVideos,
  setHighlightedVideos,
  fetchImages,
}) => {
  const dispatch = useDispatch();
  const isLogin = useSelector((state) => state.user.isLogin);
  const userInfo = useSelector((state) => state.user.userInfo);
  const user = isLogin ? JSON.parse(userInfo) : null;
  const isAdmin = user && user.Role === "admin"; // Check if user is admin

  return (
    <div>
      <div className="category-container d-flex flex-wrap gap-4 inter-font">
        {highlightedVideos.map((VideoObj, index) => (
          <SingleVid
            key={VideoObj?.id || index} // Use `id` as key if available
            video={VideoObj}
            index={index} // Pass `index` for drag-drop logic
            fetchVideos={fetchImages}
            highlightedVideos={highlightedVideos}
            setHighlightedVideos={setHighlightedVideos} // Ensure state updates
          />
        ))}
      </div>
    </div>
  );
};

export default TempVideos;
