import React, { useEffect, useState } from "react";
import Sidebar from "../../pages/SideBar";
import {
  Row,
  Col,
  Input,
  FormGroup,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import { fetchAllUsers } from "../../components/Header/Data";
import UserCard from "./UserCard";
import "./user.css";
import { FaSearch } from "react-icons/fa";
import { FadeLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";
import {
  setAllUsers,
  setFinalUsers,
  setLoadingUsers,
} from "../../redux/slices/UserStateSlice";

const User = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const { allUsers, finalUsers, loadingUsers, extRequestFilter } = useSelector(
    (state) => state.userState
  );
  const dispatch = useDispatch();

  // Function to fetch users
  const fetchUsers = async () => {
    dispatch(setLoadingUsers(true));
    try {
      const users = await fetchAllUsers();
      console.log(users); // Log users to verify the structure of the data
      dispatch(setAllUsers(users));
    } catch (error) {
      console.error("Error fetching users:", error);
    }
    dispatch(setLoadingUsers(false));
  };
  useEffect(() => {
    let users = allUsers;
    if (searchQuery) {
      users = allUsers.filter((user) => {
        const fullName = `${user.FirstName || ""} ${
          user.LastName || ""
        }`.toLowerCase(); // Safe check for missing firstName or lastName
        const lowerQuery = searchQuery.toLowerCase(); // Case insensitive search

        return (
          fullName.includes(lowerQuery) ||
          user.Email?.toLowerCase().includes(lowerQuery) ||
          user.FirstName?.toLowerCase().includes(lowerQuery) ||
          user.LastName?.toLowerCase().includes(lowerQuery) ||
          user.UserName?.toLowerCase().includes(lowerQuery)
        );
      });
    }
    //  user.phoneShow === 0 ||
    // (user.phoneShow === 1 &&
    //   user.dialExt !== null &&
    //   user.dialExt !== 0)
    // check the above condition
    if (extRequestFilter) {
      users = users.filter((user) => {
        return (
          user.phoneShow === 1 && (user.dialExt == null || user.dialExt == 0)
        );
      });
    }
    dispatch(setFinalUsers(users));
  }, [allUsers, searchQuery, extRequestFilter]);
  // Filter users based on search query

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <section className="bg-white">
      <div className="container">
        <Row>
          <Col sm={3}>
            <Sidebar />
          </Col>
          <Col sm={9} className="p-0">
            <div className="search-bar my-3 d-flex justify-content-end me-sm-2 me-md-4">
              <InputGroup className="w-sm-100 w-md-100 w-lg-50 w-xl-50">
                <Input
                  type="search"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  placeholder="Search by Email, Name, and Username"
                />
                <InputGroupText>
                  <FaSearch />
                </InputGroupText>
              </InputGroup>
            </div>
            {loadingUsers ? (
              <FadeLoader // FadeLoader component
                css={{ margin: "0 auto" }}
                color={"#36D7B7"}
                loading={loadingUsers}
                className="position-absolute translate-middle"
                style={{ left: "60%", top: "60%" }}
              />
            ) : (
              <>
                {finalUsers.length === 0 ? (
                  <div
                    className="no-users-found inter-font"
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "20px",
                      height: "500px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    No users found
                  </div>
                ) : (
                  <UserCard fetchUsers={fetchUsers} />
                )}
              </>
            )}
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default User;
