// OpportunityStage.js
import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import { FaChevronDown } from "react-icons/fa";
import { FormControl, Menu, MenuItem, Select } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setOppertunity } from "../../../../redux/slices/MsgSlice";
import { oppertunityInbox } from "../../../../components/Header/Data3";

const OpportunityStage = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentMenu, setCurrentMenu] = useState(false);
  const { oppertunity, selectedConvo } = useSelector((state) => state.messages);
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const [oppertunityStage, setOppertunityStage] = useState(false);
  const dispatch = useDispatch();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setCurrentMenu(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setCurrentMenu(false);
  };

  // const [oppertunity, setOppertunity] = useState("");
  console.log(selectedConvo, "selectedConvo");
  const addOppertunityStage = async () => {
    if (oppertunity && selectedConvo) {
      const formData = {
        messageId: selectedConvo?.Id,
        Type: oppertunity,
        createdBy:
          selectedConvo?.createdBy !== user?.UserID
            ? selectedConvo?.customerId
            : selectedConvo?.createdBy,
        receivedBy:
          selectedConvo?.createdBy === user?.UserID
            ? selectedConvo?.customerId
            : selectedConvo?.createdBy,
      };
      console.log(formData, "OppertunityStage");
      await oppertunityInbox(formData);
      setOppertunityStage(false);
    }
  };
  useEffect(() => {
    if (oppertunityStage) {
      addOppertunityStage();
    }
  }, [oppertunityStage]);
  const handleChange = (event) => {
    dispatch(setOppertunity(event.target.value));
    setOppertunityStage(true);
  };
  console.log(oppertunity, "oppertunity");
  return (
    <div className="">
      <FormControl sx={{ minWidth: 135 }}>
        <Select
          value={oppertunity}
          onChange={handleChange}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          style={{
            fontSize: "13px",
            backgroundColor: "lightgray",
          }}
          disabled={selectedConvo.createdBy === user.UserID ? false : true}
        >
          <MenuItem value={0}>
            <span>Oppertunity Stage</span>
          </MenuItem>
          <MenuItem value={1}>Lead</MenuItem>
          <MenuItem value={2}>Invoice Sent</MenuItem>
          <MenuItem value={3}>Booked</MenuItem>
          <MenuItem value={4}>Event Completed</MenuItem>
          <MenuItem value={5}>Cancelled</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};

export default OpportunityStage;
