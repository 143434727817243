import React from "react";
import { Row, Col, Card, CardBody, CardImg, Button } from "reactstrap";
import ReactStars from "react-rating-stars-component";
import { useNavigate } from "react-router-dom";
import PlaceHolder from "../../../assets/images/Placeholder.png";
import getBid from "../images/get-bids.png";
import { BiPlusCircle } from "react-icons/bi";
import { FaHeart, FaRegHeart } from "react-icons/fa";
import { Ribbon, RibbonContainer } from "react-ribbons";
import GitHubForkRibbon from "react-github-fork-ribbon";
import { addFavourite } from "../Data";
import { useDispatch, useSelector } from "react-redux";
import { setLikedCards } from "../../../redux/slices/BwvData";
import LcaBox from "../LocalCummunityAds/LcaBox";
const BwvList = ({ currentCards, currentNeeds }) => {
  const userinfo = useSelector((state) => state.user.userInfo);
  const islogin = useSelector((state) => state.user.isLogin);
  const user = islogin ? JSON.parse(userinfo) : null;
  const likedCards = useSelector((state) => state.bwvData.likedCards);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleCardClick = (selectedCard) => {
    let Id = selectedCard.Id;
    navigate(`/vendordetails/${Id}`);
  };
  function getMinPackagePrice(packageData) {
    const packages = Object.values(packageData);
    const prices = packages.map((userpackage) =>
      parseFloat(userpackage.price.trim())
    );
    const minPrice = Math.min(...prices);
    return minPrice;
  }
  const handleFavourite = async (e, card) => {
    e.stopPropagation();
    const formData = {
      userId: user.UserID,
      postId: card.Id,
      postType: "Service",
    };
    const updatedLikedCards = {
      ...likedCards,
      [card.Id]: !likedCards[card.Id], // Toggle liked state
    };
    dispatch(setLikedCards(updatedLikedCards));
    await addFavourite(formData);
  };

  return (
    <Row className="bwv-content p-3">
      {currentCards.length > 0 ? (
        <>
          {currentCards.map((provider) => (
            <Card
              key={provider.Id}
              style={{ cursor: "pointer", padding: "0px" }}
              onClick={() => handleCardClick(provider)}
            >
              {provider?.caIdStatus === 1 ? (
                <GitHubForkRibbon color="green" position="left">
                  Verified & Trusted
                </GitHubForkRibbon>
              ) : null}
              {islogin && (
                <button
                  style={{
                    position: "absolute",
                    right: "1%",
                    top: "1%",
                    zIndex: 1,
                  }}
                  onClick={(e) => handleFavourite(e, provider)}
                  //disabled={isAddingToFavorites}
                >
                  {likedCards[provider.Id] ? (
                    <FaHeart size={24} style={{ color: "red" }} />
                  ) : (
                    <FaRegHeart size={24} />
                  )}
                </button>
              )}
              <Row>
                <Col sm={3} xs={12}>
                  <CardImg
                    src={
                      provider.Photo &&
                      JSON.parse(provider.Photo).length > 0 &&
                      typeof provider.Photo[0] !== ""
                        ? JSON.parse(provider.Photo)[0]
                        : PlaceHolder
                    }
                    alt={provider.listName}
                    style={{ height: "150px" }}
                    className="position-relative"
                  />
                  <p className="bg-secondary text-white rounded-pill px-3 py-2 need-category-display text-center">
                    {provider?.categoryName}
                  </p>
                </Col>
                <Col sm={9} xs={12}>
                  <CardBody
                    className="text-start"
                    style={{ minHeight: "auto" }}
                  >
                    <p style={{ fontSize: "small" }}>{provider.listName}</p>

                    <h5>{provider.specialOffer}</h5>
                    <Row className="pt-4 w-100">
                      <Col sm={6} style={{ fontSize: "small" }}>
                        <ReactStars
                          count={5}
                          size={22}
                          value={provider.Rating}
                          activeColor="#ffd700"
                          edit={false}
                        />
                      </Col>
                      {/* <Col sm={3} style={{ color: "blue", marginTop: "8px" }}>
                        <h6>
                          Price: ${" "}
                          {provider.hourlyPrice
                            ? provider.hourlyPrice
                            : getMinPackagePrice(JSON.parse(provider.Package))}
                        </h6>
                      </Col> */}
                      <Col
                        sm={6}
                        style={{
                          marginTop: "8px",
                          textWrap: "nowrap",
                          fontSize: "15px",
                        }}
                      >
                        {provider.endName && (
                          <p>
                            <span className="fw-bold">Recommeded by </span>
                            {provider.endName.split(",").length}{" "}
                            {provider.endName.split(",").length === 1
                              ? "vendor"
                              : "vendors"}
                            &nbsp;in the wedding industry
                          </p>
                        )}
                      </Col>
                    </Row>
                  </CardBody>
                </Col>
              </Row>
              {/* </RibbonContainer> */}
            </Card>
          ))}
          {currentNeeds && currentNeeds.map((need) => <LcaBox ad={need} />)}
        </>
      ) : (
        <div className="d-flex justify-content-center align-items-center">
          <div className="mt-3 text-center">
            {" "}
            <img src={getBid} alt="bags" style={{ width: "10rem" }} />
            <p className="mb-2">
              {" "}
              Have something to sell? Start making money right away!
            </p>
            <Button
              type="button"
              className="btn btn-danger"
              onClick={() => {
                if (islogin) {
                  navigate("/create-service");
                } else {
                  navigate("/login");
                }
              }}
            >
              <BiPlusCircle /> Add New
            </Button>
          </div>
        </div>
      )}
    </Row>
  );
};

export default BwvList;
