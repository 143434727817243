import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaCaretDown, FaSlidersH } from "react-icons/fa";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import PlaceHolder from "../../../../assets/images/Placeholder.png";
import { FadeLoader } from "react-spinners";
import MsgModal from "../../modals/MsgModal";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Button,
  Container,
} from "reactstrap";
import LcaCardQuestions from "../LcaCardsQuestions";
import LcaCardsEvents from "../LcaCardsEvents";
import "../LcaCards.css"; // Import your custom CSS file
import moment from "moment-timezone";
import "moment-timezone/builds/moment-timezone-with-data";
import LcaCardButtons from "../LcaCardButtons";
import { fetchBWV, fetchUserDetails } from "../../Data";
import CarouselComponent from "../BizzList";
import GitHubForkRibbon from "react-github-fork-ribbon";

const BwvCards2 = ({ allProducts, setLoading, carouselData }) => {
  const location = useLocation();
  const islogin = useSelector((state) => state.user.isLogin);
  const userdata = useSelector((state) => state.user);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const [isMessage, setIsMessage] = useState(false);
  const [isMsgModalOpen, setIsMsgModalOpen] = useState(false);
  const [isOfferModalOpen, setIsOfferModalOpen] = useState(false);
  const [selectedAd, setSelectedAd] = useState(null);
  const [schedule, setSchedule] = useState(false);
  const navigate = useNavigate();
  const [questionsVisible, setQuestionsVisible] = useState({});
  const [eventsVisible, setEventsVisible] = useState({});
  const [resp, setResp] = useState("");
  const [imageIndex, setImageIndex] = useState({});
  const { bwvLoading } = useSelector((state) => state.lcAds);
  const closeModal = () => {
    setIsMsgModalOpen(false);
    // setIsOfferModalOpen(false);
    setSelectedAd(null);
    // setSchedule(false);
  };

  const handleCardClick = (selectedCard) => {
    const screenWidth = window.innerWidth;
    const isIpadPro = screenWidth === 1024; // Check for iPad Pro screen width
    let Id = selectedCard.Id;
    if (!isMsgModalOpen && !isOfferModalOpen) {
      if (isIpadPro) {
        navigate(`/vendordetails/${Id}`);
      } else {
        window.open(`#/vendordetails/${Id}`, "_blank");
      }
    }
  };

  if (!allProducts) {
    <p>No Ads</p>;
    return;
  }
  function convertTo12HourFormat(time24) {
    if (!time24) {
      return "";
    }
    const [hour24, minute] = time24.split(":").map(Number);
    if (isNaN(hour24) || isNaN(minute)) {
      return "";
    }
    const period = hour24 >= 12 ? "PM" : "AM";
    const hour12 = (hour24 % 12 || 12).toString().padStart(2, "0"); // Added padStart
    return `${hour12}:${minute.toString().padStart(2, "0")} ${period}`;
  }

  function formatDate(inputDate) {
    let date = new Date(inputDate);
    let monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    let year = date.getFullYear();
    let month = monthNames[date.getMonth()];
    let day = date.getDate();
    let formattedDay = day < 10 ? "0" + day : day;
    let formattedDate = `${month} ${formattedDay}, ${year}`;
    return formattedDate;
  }
  const openMsgModal = (event, ad) => {
    event.stopPropagation();
    if (!islogin) {
      toast.info("Please Login");
      navigate("/login");
    } else {
      setIsMessage(true);
      setIsMsgModalOpen(true);
      setSelectedAd(ad);
    }
  };
  function convertToUserTimezone(utcTime) {
    let timezone = "America/Los_Angeles"; // Default to system timezone
    if (islogin && user?.TimeZone) {
      timezone = user?.TimeZone.split(",")[0];
    }
    const localTime = moment
      .utc(utcTime)
      .tz(timezone)
      .format("YYYY-MM-DD HH:mm:ss");
    return localTime;
  }

  const handleNextImage = (event, ad) => {
    event.stopPropagation();
    const currentIndex = imageIndex[ad.Id] || 0;
    const images = JSON.parse(ad.Photo);
    const nextIndex = (currentIndex + 1) % images.length;
    setImageIndex({ ...imageIndex, [ad.Id]: nextIndex });
  };

  const handlePrevImage = (event, ad) => {
    event.stopPropagation();
    const currentIndex = imageIndex[ad.Id] || 0;
    const images = JSON.parse(ad.Photo);
    const prevIndex = (currentIndex - 1 + images.length) % images.length;
    setImageIndex({ ...imageIndex, [ad.Id]: prevIndex });
  };
  if (!allProducts.length) {
    return <div>No products available</div>; // Fallback UI
  }
  if (bwvLoading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "40vh" }}
      >
        <FadeLoader color={"#36D7B7"} />
      </div>
    );
  }
  return (
    <Container>
      {allProducts && allProducts.length > 0 ? (
        <>
          {allProducts.map((ad, index) => (
            <div key={ad.Id}>
              <p className="mb-0" style={{ fontSize: "medium" }}>
                {ad?.FullName}
              </p>
              <Card className="shadow-sm position-realtive">
                {ad?.caIdStatus === 1 ? (
                  <GitHubForkRibbon color="green" position="left">
                    Verified & Trusted
                  </GitHubForkRibbon>
                ) : null}
                <Row
                  className="g-0"
                  // style={
                  //   scrollPopupVisible && index === 0
                  //     ? {
                  //         animation: "flashingRed 2s infinite",
                  //       }
                  //     : {}
                  // }
                >
                  <div
                    className="position-absolute"
                    style={{
                      backgroundColor: "white",
                      width: "auto",
                      padding: "0 3px 3px 0",
                    }}
                  >
                    <img
                      className=""
                      style={{
                        width: "20px",
                      }}
                      src={ad?.profilePhoto ? ad?.profilePhoto : PlaceHolder}
                      alt={ad?.FullName}
                    />
                    {/* <p className="mt-2 me-1" style={{ fontSize: "medium" }}>
                {ad?.FullName}
              </p> */}
                  </div>
                  <Col lg="3" md="3" className="d-flex align-items-center ">
                    <div className="position-relative">
                      {ad.Photo && JSON.parse(ad.Photo).length > 1 && (
                        <button
                          className="arrow left-arrow position-absolute"
                          style={{
                            top: "50%",
                            left: "3px",
                            transform: "translateY(-50%)",
                            backgroundColor: "rgba(255, 255, 255, 0.5)",
                            border: "none",
                            fontSize: "30px",
                            zIndex: 1,
                          }}
                          onClick={(event) => handlePrevImage(event, ad)}
                        >
                          &#10094;
                        </button>
                      )}
                    </div>
                    <img
                      className="wc_egcpl-ad img-fluid"
                      src={
                        ad.Photo && JSON.parse(ad.Photo).length > 0
                          ? JSON.parse(ad.Photo)[imageIndex[ad.Id] || 0]
                          : PlaceHolder
                      }
                      alt={ad.Title}
                      style={{
                        objectFit: "fill",
                        height: "220px",
                        width: "220px",
                      }}
                    />
                    <div className="position-relative">
                      {ad.Photo && JSON.parse(ad.Photo).length > 1 && (
                        <button
                          className="arrow right-arrow position-absolute"
                          style={{
                            top: "50%",
                            right: "3px",
                            transform: "translateY(-50%)",
                            backgroundColor: "rgba(255, 255, 255, 0.5)",
                            border: "none",
                            fontSize: "30px",
                            zIndex: 1,
                          }}
                          onClick={(event) => handleNextImage(event, ad)}
                        >
                          &#10095;
                        </button>
                      )}
                    </div>
                  </Col>
                  <Col lg="7" md="7">
                    <CardBody
                      onClick={() => handleCardClick(ad)}
                      className="lca-card-body"
                    >
                      <CardTitle
                        tag="h5"
                        className="mb-2"
                        style={{
                          fontSize: "large",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        <b>
                          {" "}
                          {ad.listName.length > 100
                            ? `${ad.listName.substring(0, 100)}...`
                            : ad.listName}
                        </b>
                      </CardTitle>
                      <CardText
                        style={{
                          fontSize: "medium",
                          // overflow: "hidden",
                          // textOverflow: "ellipsis",
                          // whiteSpace: "nowrap",
                          marginLeft: 0,
                        }}
                      >
                        {ad.Description.length > 200
                          ? `${ad.Description.substring(0, 200)}...`
                          : ad.Description}
                      </CardText>
                      <div className="d-lg-flex">
                        {ad.Location && (
                          <CardText
                            className="text-nowrap mx-0"
                            style={{ fontSize: "medium" }}
                          >
                            <FontAwesomeIcon icon={faMapMarkerAlt} />
                            <span className=""> {ad.Location}</span>
                          </CardText>
                        )}
                        {ad.categoryName && (
                          <CardText className="" style={{ fontSize: "medium" }}>
                            <b>Service Provided:</b>{" "}
                            {ad.itemName
                              ? ad.itemName
                              : ad.subCategoryName
                              ? ad.subCategoryName
                              : ad.categoryName
                              ? ad.categoryName
                              : ""}
                          </CardText>
                        )}
                      </div>
                      <div>
                        <p
                          className="mb-0 posting"
                          style={{ fontSize: "small" }}
                        >
                          Posting Date/Time:{" "}
                          {/* {formatDate(ad?.createdAt.split("T")[0])} &nbsp;
                      {convertTo12HourFormat(
                        ad?.createdAt.split("T")[1].split("T")[0]
                      )}
                      &nbsp; &nbsp; */}
                          {formatDate(
                            convertToUserTimezone(ad?.createdAt).split(" ")[0]
                          )}
                          &nbsp;
                          {convertTo12HourFormat(
                            convertToUserTimezone(ad?.createdAt).split(" ")[1]
                          )}
                        </p>
                      </div>
                      {/* <div className=" d-flex align-items-center gap-3 mt-1">
                    <img
                      className=""
                      style={{
                        width: "20px",
                      }}
                      src={ad?.profilePhoto ? ad?.profilePhoto : PlaceHolder}
                      alt={ad?.firstName}
                    />
                    <p className="mt-2 me-1" style={{ fontSize: "medium" }}>
                      {ad?.FullName}
                    </p>
                  </div> */}
                    </CardBody>
                  </Col>
                  <Col
                    lg="2"
                    md="2"
                    className="d-flex flex-column align-items-center justify-content-center"
                  >
                    <div className="w-100 text-center need">
                      <a
                        href={`tel: 8002453610`}
                        className="btn btn-success btn-sm mb-2 w-100"
                      >
                        Call: (800) 245-3610 <br />
                        <span>Extension # {ad.dialExtension}</span>
                      </a>
                      <Button
                        color="primary"
                        size="sm"
                        className="w-100"
                        onClick={(event) => openMsgModal(event, ad)}
                      >
                        Send Message
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Card>
              {index === 2 && carouselData.row1.length > 0 && (
                <Card className="my-2 mb-0 border-white">
                  <CarouselComponent items={carouselData.row1} />
                </Card>
              )}
              {index === 5 && carouselData.row2.length > 0 && (
                <Card className="my-2 mb-0 border-white">
                  <CarouselComponent items={carouselData.row2} />
                </Card>
              )}
              {index === 8 && carouselData.row3.length > 0 && (
                <Card className="my-2 mb-0 border-white">
                  <CarouselComponent items={carouselData.row3} />
                </Card>
              )}
            </div>
          ))}
        </>
      ) : (
        <div className="text-center my-5"></div>
        // <div
        //   className="text-center py-5 text-primary"
        //   style={{
        //     display: "flex",
        //     alignItems: "center",
        //     justifyContent: "center",
        //     margin: "50px 0px",
        //   }}
        // >
        //   <p
        //     style={{
        //       display: "flex",
        //       alignItems: "center",
        //       justifyContent: "center",
        //       margin: "10px 0px",
        //       fontSize: "20px",
        //     }}
        //     className="text-center py-5"
        //   >
        //     No cards to display
        //   </p>
        // </div>
      )}

      <MsgModal
        isOpen={isMsgModalOpen}
        closeModal={closeModal}
        selectedCard={selectedAd}
      />
    </Container>
  );
};

export default BwvCards2;
