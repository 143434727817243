/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Input, Button } from "reactstrap";
import { fetchCategories, fetchSubCategories, fetchItems } from "../Data";
import {
  setBwvSearchQuery,
  setSelectedQuary,
} from "../../../redux/slices/BwvData";
import { useDispatch, useSelector } from "react-redux";
export const SearchBwv = () => {
  const {
    originalNeeds,
    originalBwv,
    searchTerm,
    bwvSearchQuery,
    selectedQuary,
  } = useSelector((state) => state.bwvData);
  const dispatch = useDispatch();
  const [cat, setCat] = useState([]);
  const [subCat, setSubCat] = useState([]);
  const [activeSuggestion, setActiveSuggestion] = useState(0);
  const onSearch = () => {
    setActiveSuggestion(null);
  };
  useEffect(() => {
    const fetchCat = async () => {
      const categoriesData = await fetchCategories();
      const subCategoriesData = await fetchSubCategories();
      // const itemsData = await fetchItems();
      setCat(categoriesData);
      setSubCat(subCategoriesData);
      // setItem(itemsData);
    };
    fetchCat();
  }, []);
  useEffect(() => {
    if (bwvSearchQuery == "") {
      dispatch(setSelectedQuary(null));
    }
  }, [bwvSearchQuery]);

  const filteredData = [
    ...cat,
    ...subCat,
    ...originalBwv,
    ...originalNeeds,
  ].filter((data) => {
    return (
      !bwvSearchQuery ||
      (data.Name &&
        data.Name.toLowerCase().includes(bwvSearchQuery.toLowerCase())) ||
      (data.listName &&
        data.listName.toLowerCase().includes(bwvSearchQuery.toLowerCase())) ||
      (data.Location &&
        data.Location.toLowerCase().includes(bwvSearchQuery.toLowerCase())) ||
      (data.Description &&
        data.Description.toLowerCase().includes(
          bwvSearchQuery.toLowerCase()
        )) ||
      (data.Title &&
        data.Title.toLowerCase().includes(bwvSearchQuery.toLowerCase()))
    );
  });
  const handleSelect = (data, index, type) => {
    let name;
    if (
      bwvSearchQuery &&
      data.listName &&
      data.listName.toLowerCase().includes(bwvSearchQuery.toLowerCase())
    ) {
      name = data.listName;
    } else if (
      bwvSearchQuery &&
      data.Description &&
      data.Description.toLowerCase().includes(bwvSearchQuery.toLowerCase())
    ) {
      name = data.Description;
    } else if (
      bwvSearchQuery &&
      data.Name &&
      data.Name.toLowerCase().includes(bwvSearchQuery.toLowerCase())
    ) {
      name = data.Name;
    } else if (
      bwvSearchQuery &&
      data.Location &&
      data.Location.toLowerCase().includes(bwvSearchQuery.toLowerCase())
    ) {
      name = data.Location;
    } else if (
      bwvSearchQuery &&
      data.Title &&
      data.Title.toLowerCase().includes(bwvSearchQuery.toLowerCase())
    ) {
      name = data.Title;
    } else {
      name = data.listName || data.Description || data.Name || data.Location;
    }
    dispatch(setSelectedQuary(name));
    dispatch(setBwvSearchQuery(name));
    setActiveSuggestion(index);
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      onSearch();
    }
  };
  return (
    <>
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Input
            type="search"
            className="m-2"
            style={{
              width: "71%",
              position: "relative",
            }}
            value={bwvSearchQuery}
            onChange={(e) => dispatch(setBwvSearchQuery(e.target.value))}
            placeholder="Search..."
            onKeyPress={handleKeyPress}
          />
          <Button className="bg-primary m-0" onClick={onSearch}>
            Search
          </Button>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            position: "absolute",
            zIndex: 9,
            backgroundColor: "#FAF9F6",
            color: "white",
            left: "316px",
            width: "48%",
            borderRadius: "10px",
          }}
        >
          {!selectedQuary && bwvSearchQuery && (
            <ul className="list m-0 p-0 list-group" style={{ width: "100%" }}>
              {filteredData.length > 0 ? (
                filteredData.map((data, index) => (
                  <li
                    key={data.id}
                    onClick={() => {
                      handleSelect(data, index);
                      setActiveSuggestion(index);
                      dispatch(setSelectedQuary(data));
                    }}
                    onMouseEnter={(e) => {
                      e.target.style.backgroundColor = "#5435C2";
                      e.target.style.color = "white";
                      e.target.style.borderRadius = "10px";
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.backgroundColor = "white";
                      e.target.style.color = "black";
                    }}
                    style={
                      activeSuggestion === index
                        ? {
                            backgroundColor: "#5435C2",
                            color: "white",
                            borderRadius: "10px",
                          }
                        : {}
                    }
                    className="list-group-item text-decoration-none text-start w-100 d-flex justify-content-between align-items-center"
                  >
                    {bwvSearchQuery &&
                    data.listName &&
                    data.listName
                      .toLowerCase()
                      .includes(bwvSearchQuery.toLowerCase())
                      ? data.listName
                      : bwvSearchQuery &&
                        data.Description &&
                        data.Description.toLowerCase().includes(
                          bwvSearchQuery.toLowerCase()
                        )
                      ? data.Description
                      : bwvSearchQuery &&
                        data.Name &&
                        data.Name.toLowerCase().includes(
                          bwvSearchQuery.toLowerCase()
                        )
                      ? data.Name
                      : data.Title ||
                        data.FullName ||
                        data.Description ||
                        data.Name ||
                        data.catName ||
                        data.listName}
                  </li>
                ))
              ) : (
                <li className="list-group-item text-center fw-bold">
                  No search results
                </li>
              )}
            </ul>
          )}
        </div>
      </div>
    </>
  );
};
