import React, { useEffect, useState } from "react";
import { Row, Col, Input, FormGroup, Label, Button } from "reactstrap";
import {
  fetchOptionsList,
  fetchOptions,
} from "../../../components/Header/Data";
import {
  setCustomDetails,
  setMandate,
} from "../../../redux/slices/ServiceInfo";
import { useDispatch, useSelector } from "react-redux";

const CustomFields = () => {
  const dispatch = useDispatch();
  const serviceInfo = useSelector((state) => state.serviceInfo);
  const [listOptions, setListOptions] = useState([]);
  const [optionsData, setOptionsData] = useState([]);
  const [visibleFields, setVisibleFields] = useState({});
  const [additionalFields, setAdditionalFields] = useState([]);
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const isAdmin = user && user.Role === "admin"; // Check if user is admin
  const [fieldDetails, setFieldDetails] = useState({
    Title: "",
    Description: "",
  });
  const [showAdditionalFields, setShowAdditionalFields] = useState(false); // Initially hidden

  useEffect(() => {
    fetchDetailsOptions();
  }, [serviceInfo.categoryId]);

  useEffect(() => {
    if (listOptions.length > 0) {
      fetchOptionsData();
    }
  }, [listOptions]);

  const fetchDetailsOptions = async () => {
    try {
      const listData = await fetchOptionsList(serviceInfo.categoryId);
      setListOptions(listData);

      const initialVisibility = {};
      listData.forEach((option) => {
        initialVisibility[option.cat_list_id] = true;
      });
      setVisibleFields(initialVisibility);
    } catch (error) {
      console.error("Error fetching categories:", error.message);
    }
  };

  const fetchOptionsData = async () => {
    const options = {};
    for (const option of listOptions) {
      try {
        const data = await fetchOptions(option.cat_list_id);
        options[option.cat_list_id] = data;
      } catch (error) {
        console.error("Error fetching options:", error.message);
      }
    }
    setOptionsData(options);
  };

  const toggleFieldVisibility = (fieldKey) => {
    // Create a deep copy of serviceInfo.customDetails
    const newCustomDetails = JSON.parse(
      JSON.stringify(serviceInfo.customDetails)
    );

    if (!newCustomDetails[fieldKey]) {
      newCustomDetails[fieldKey] = [
        { option_value: "", option_name: "", is_visible: true },
      ];
    } else {
      // Toggle the visibility
      newCustomDetails[fieldKey] = newCustomDetails[fieldKey].map((item) => ({
        ...item,
        is_visible: !item.is_visible,
      }));
    }

    dispatch(setCustomDetails(newCustomDetails));
  };
  useEffect(() => {
    const newAdditionalFields = Object.keys(serviceInfo.customDetails)
      .filter((key) => !listOptions.some((option) => option.list_name === key)) // Only additional fields
      .map((key) => ({
        Title: key,
        Description: serviceInfo.customDetails[key]?.[0]?.option_value || "",
      }));

    setAdditionalFields(newAdditionalFields);
  }, [serviceInfo.customDetails, listOptions]);

  const handleInputChange = (event, option, opt) => {
    const { value } = event.target;
    const fieldKey = option.list_name;
    const newCustomDetails = { ...serviceInfo.customDetails };

    if (option.list_type === "dropdown") {
      newCustomDetails[fieldKey] = opt
        ? [
            {
              option_value: value,
              option_name: opt.option_name,
              is_visible: true,
            },
          ]
        : [];
    } else if (option.list_type === "checkbox") {
      const existingOptions = newCustomDetails[fieldKey] || [];
      newCustomDetails[fieldKey] = event.target.checked
        ? [
            ...existingOptions,
            {
              option_value: value,
              option_name: opt?.option_name || "",
              is_visible: true,
            },
          ]
        : existingOptions.filter((item) => item.option_value !== value);
    } else if (option.list_type === "textbox") {
      newCustomDetails[fieldKey] = [
        { option_value: value, option_name: value, is_visible: true },
      ];
    }

    dispatch(setCustomDetails(newCustomDetails));
    // checkMandatoryFields(newCustomDetails);
  };
  const checkMandatoryFields = (customDetails) => {
    let allMandatoryFilled = true;
    listOptions.forEach((option) => {
      if (option.is_mandatory === "1") {
        const value = customDetails[option.list_name] || [];
        if (value.length === 0 || !value[0].option_value) {
          allMandatoryFilled = false;
        }
      }
    });
    dispatch(setMandate(allMandatoryFilled));
  };
  useEffect(() => {
    checkMandatoryFields(serviceInfo.customDetails);
  }, [serviceInfo.customDetails]); // 🔥 Runs when customDetails updates

  const handleAddField = () => {
    setShowAdditionalFields(true); // Show input fields on button click
  };

  const handleSaveField = () => {
    if (!fieldDetails.Title.trim() || !fieldDetails.Description.trim()) return;

    const newCustomDetails = {
      ...serviceInfo.customDetails,
      [fieldDetails.Title]: [
        {
          option_value: fieldDetails.Description,
          option_name: fieldDetails.Description,
          is_visible: true,
        },
      ],
    };

    dispatch(setCustomDetails(newCustomDetails));
    setAdditionalFields([...additionalFields, { ...fieldDetails }]);

    // Ensure visibility is initialized
    setVisibleFields((prev) => ({
      ...prev,
      [fieldDetails.Title]: true,
    }));

    // Reset inputs
    setFieldDetails({ Title: "", Description: "" });
    setShowAdditionalFields(false); // Hide input fields after saving
  };

  const renderInputField = (option) => {
    const options = optionsData[option.cat_list_id] || [];
    const fieldKey = option.list_name;
    const value = serviceInfo.customDetails[fieldKey] || [];

    switch (option.list_type) {
      case "dropdown":
        return (
          <Input
            type="select"
            value={value.length > 0 ? value[0]?.option_value : ""}
            onChange={(event) => {
              const selectedOption = options.find(
                (opt) => opt.option_value === parseInt(event.target.value)
              );
              handleInputChange(event, option, selectedOption);
            }}
          >
            <option value="">Select option</option>
            {options.map((opt, index) => (
              <option key={index} value={opt.option_value}>
                {opt.option_name}
              </option>
            ))}
          </Input>
        );
      case "checkbox":
        return options.map((opt, index) => (
          <FormGroup check key={index} className="m-2">
            <Label check>
              <Input
                type="checkbox"
                checked={value.some(
                  (item) => item.option_name === opt.option_name
                )}
                value={opt.option_value}
                onChange={(event) => handleInputChange(event, option, opt)}
              />{" "}
              {opt.option_name}
            </Label>
          </FormGroup>
        ));
      case "textbox":
        return (
          <Input
            type="text"
            value={value.map((item) => item.option_value)}
            onChange={(event) =>
              handleInputChange(event, option, {
                option_value: event.target.value,
                option_name: event.target.value,
              })
            }
          />
        );
      default:
        return null;
    }
  };
  const handleDecChange = (e, field, index) => {
    const updatedFields = additionalFields.map((field, idx) => {
      if (idx === index) {
        return { ...field, Description: e.target.value };
      }
      return field;
    });
    setAdditionalFields(updatedFields);
    // Only update serviceInfo.customDetails when there's a valid change
    const newCustomDetails = { ...serviceInfo.customDetails };
    newCustomDetails[field.Title] = [
      {
        option_value: e.target.value,
        option_name: e.target.value,
      },
    ];
    dispatch(setCustomDetails(newCustomDetails));
  };
  const ondDecBlur = (e, field, index) => {
    const newCustomDetails = { ...serviceInfo.customDetails };
    newCustomDetails[field.Title] = [
      {
        option_value: e.target.value,
        option_name: e.target.value,
      },
    ];
    dispatch(setCustomDetails(newCustomDetails));
  };
  const handleDeleteField = (index, fieldTitle) => {
    // Remove field from additionalFields
    const updatedFields = additionalFields.filter((_, idx) => idx !== index);
    setAdditionalFields(updatedFields);

    // Remove field from Redux state (serviceInfo.customDetails)
    const newCustomDetails = { ...serviceInfo.customDetails };
    delete newCustomDetails[fieldTitle];
    dispatch(setCustomDetails(newCustomDetails));
  };
  return (
    <Row className="form-container p-5 m-3">
      <h6 className="pb-3">Custom Fields</h6>

      {listOptions.length > 0 && (
        <Col>
          {listOptions.map((option, index) => (
            <Row key={index}>
              <div className="d-flex justify-content-between align-items-center">
                <span>
                  {option.list_name}{" "}
                  {option.is_mandatory === "1" && (
                    <b className="text-danger">*</b>
                  )}
                </span>
                {isAdmin && (
                  <Button
                    color="primary"
                    size="sm"
                    onClick={() => toggleFieldVisibility(option.list_name)} // Use fieldKey instead of cat_list_id
                  >
                    {serviceInfo.customDetails[option.list_name]?.[0]
                      ?.is_visible
                      ? "Hide"
                      : "Show"}
                  </Button>
                )}
              </div>
              <span style={{ fontSize: "small", padding: "10px" }}>
                {option.description}
              </span>
              {visibleFields[option.cat_list_id] && (
                <Col
                  style={{ display: "flex", flexWrap: "wrap" }}
                  className="pb-4"
                >
                  {renderInputField(option)}
                </Col>
              )}
            </Row>
          ))}
        </Col>
      )}
      {additionalFields.length > 0 && (
        <Row>
          <h6 className="pb-3">Additional Fields</h6>
          {additionalFields.map((field, index) => (
            <div key={index} className="pb-3">
              <div className="d-flex justify-content-between align-items-center pb-3">
                <span>{field.Title} </span>{" "}
                <div>
                  <Button
                    color="danger"
                    size="sm"
                    className="me-1"
                    onClick={() => handleDeleteField(index, field.Title)}
                  >
                    Delete
                  </Button>
                  {isAdmin && (
                    <Button
                      color="primary"
                      size="sm"
                      onClick={() => toggleFieldVisibility(field.Title)} // Use field.Title instead of list_name
                    >
                      {serviceInfo.customDetails[field.Title]?.[0]?.is_visible
                        ? "Hide"
                        : "Show"}
                    </Button>
                  )}
                </div>
              </div>
              <Input
                type="text"
                value={field.Description}
                placeholder="Description"
                className="decrease-in-additional-fields"
                onBlur={(e) => ondDecBlur(e, field, index)}
                onChange={(e) => handleDecChange(e, field, index)}
              />
            </div>
          ))}
        </Row>
      )}

      {/* Show input fields only when the button is clicked */}
      {showAdditionalFields && (
        <div className="pb-3">
          <h6 className="pb-3">Additional Details</h6>
          <Input
            type="text"
            placeholder="Title"
            value={fieldDetails.Title}
            onChange={(e) =>
              setFieldDetails({ ...fieldDetails, Title: e.target.value })
            }
            className="mb-3"
          />
          <Input
            type="textarea"
            placeholder="Description"
            value={fieldDetails.Description}
            onChange={(e) =>
              setFieldDetails({ ...fieldDetails, Description: e.target.value })
            }
          />
          <Button className="mt-2" color="success" onClick={handleSaveField}>
            Save Field
          </Button>
        </div>
      )}

      <Button className="btn btn-primary mt-3" onClick={handleAddField}>
        Add New Field
      </Button>
    </Row>
  );
};

export default CustomFields;
