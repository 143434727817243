import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, CardImg, Button } from "reactstrap";
import ReactStars from "react-rating-stars-component";
import { useNavigate } from "react-router-dom";
import PlaceHolder from "../../../assets/images/Placeholder.png";
import getBid from "../images/get-bids.png";
import { BiPlusCircle } from "react-icons/bi";
import { FaHeart, FaRegHeart } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import BwvGridImage from "./BwvGridImage";
import { Ribbon, RibbonContainer } from "react-ribbons";
import GitHubForkRibbon from "react-github-fork-ribbon";
import { addFavourite } from "../Data";
import { setLikedCards } from "../../../redux/slices/BwvData";

const BwvGrid = ({ currentCards, currentNeeds }) => {
  const userinfo = useSelector((state) => state.user.userInfo);
  const islogin = useSelector((state) => state.user.isLogin);
  const user = islogin ? JSON.parse(userinfo) : null;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const likedCards = useSelector((state) => state.bwvData.likedCards);
  const handleCardClick = (selectedCard) => {
    let Id = selectedCard.Id;
    navigate(`/vendordetails/${Id}`);
  };
  const handleNeedCardClick = (selectedCard) => {
    const screenWidth = window.innerWidth;
    const isIpadPro = screenWidth === 1024; // Check for iPad Pro screen width
    if (isIpadPro) {
      navigate(`/requestDetails/${selectedCard.Id}`);
    } else {
      window.open(`#/requestDetails/${selectedCard.Id}`, "_blank");
    }
  };
  console.log(currentCards, "currentCards");
  function getMinPackagePrice(packageData) {
    const packages = Object.values(packageData);
    const prices = packages.map((userpackage) =>
      parseFloat(userpackage.price.trim())
    );
    const minPrice = Math.min(...prices);
    return minPrice;
  }
  const handleFavourite = async (e, card) => {
    e.stopPropagation();
    const formData = {
      userId: user.UserID,
      postId: card.Id,
      postType: "Service",
    };

    const updatedLikedCards = {
      ...likedCards,
      [card.Id]: !likedCards[card.Id], // Toggle liked state
    };
    dispatch(setLikedCards(updatedLikedCards));
    await addFavourite(formData);
  };
  if (!currentCards.length && !currentNeeds.length) {
    return <div className="text-center p-5">No products available</div>; // Fallback UI
  }

  return (
    <Row className="bwv-content pt-4">
      {currentNeeds.length > 0 || currentCards.length > 0 ? (
        <>
          {currentCards.map((provider, i) => GridBwvCards(provider))}
          {currentNeeds.map((need, i) => needsInBwvGrid(need))}
        </>
      ) : (
        <div className="d-flex justify-content-center align-items-center">
          <div className="mt-3 text-center">
            {" "}
            <img src={getBid} alt="bags" style={{ width: "10rem" }} />
            <p className="mb-2">
              {" "}
              Have something to sell? Start making money right away!
            </p>
            <Button
              type="button"
              className="btn btn-danger"
              onClick={() => {
                if (islogin) {
                  navigate("/create-service");
                } else {
                  navigate("/login");
                }
              }}
            >
              <BiPlusCircle /> Add New
            </Button>
          </div>
        </div>
      )}
    </Row>
  );

  function GridBwvCards(provider) {
    return (
      <Col key={provider.Id} lg="4" className="position-relative">
        <Card
          style={{ cursor: "pointer", height: "100%" }}
          onClick={() => handleCardClick(provider)}
        >
          {provider?.caIdStatus === 1 ? (
            <GitHubForkRibbon color="green" position="left">
              Verified & Trusted
            </GitHubForkRibbon>
          ) : null}
          {islogin && (
            <button
              style={{
                position: "absolute",
                right: "4%",
                top: "4%",
                zIndex: 1,
                transform: "translateY(-50%)",
                backgroundColor: "rgba(255, 255, 255, 0.5)",
                border: "none",
                borderRadius: "10%",
              }}
              onClick={(e) => handleFavourite(e, provider)}
            >
              {likedCards[provider.Id] ? (
                <FaHeart size={24} style={{ color: "red" }} />
              ) : (
                <FaRegHeart size={24} />
              )}
            </button>
          )}
          <span
            style={{
              position: "absolute",
              top: "50%",
              left: "2%",
              fontWeight: "bold",
              color: "#fff",
            }}
          >
            {/* {provider.listName} */}
          </span>
          <BwvGridImage provider={provider} />
          <p
            style={{
              position: "absolute",
              top: "40%",
              left: "50%",
              textWrap: "nowrap",
              color: "black",
              transform: "translateX(-50%)",
            }}
            className="bg-secondary text-white rounded-pill px-3 py-2 text-center"
          >
            {provider?.categoryName}
          </p>
          <CardBody
            style={{
              maxHeight: "110px",
              overflow: "hidden",
              gap: 5,
              minHeight: "225px",
            }}
          >
            {/* <p>{provider.description}</p> */}
            <p
              style={{
                fontSize: "small",
                marginBottom: "0.5rem",
                marginTop: 0,
              }}
              className="mt-4 text-nowrap"
            >
              {provider.listName}
            </p>
            <h6
              className="provider-name"
              style={{
                color: "black",
                textAlign: "left",
                // margin: "10px",
                // maxHeight: "50px",
                // height: "50px",
              }}
            >
              {provider?.specialOffer?.length > 90
                ? `${provider.specialOffer.substring(0, 90)}...`
                : provider.specialOffer}
            </h6>

            <div
              style={{
                // position: "absolute",
                // bottom: "6px",
                // left: "0",
                width: "100%",
                padding: "0px 15px",
                //backgroundColor: "rgba(255, 255, 255, 0.8)",
              }}
            >
              <Row>
                <Col
                  // sm={6}
                  // xs={12}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <ReactStars
                    count={5}
                    size={22}
                    value={provider.Rating}
                    activeColor="#ffd700"
                    edit={false}
                  />
                </Col>
                {/* <Col
          sm={6}
          xs={12}
          style={{
            color: "blue",
            textAlign: "right",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h6 className="mb-0">
            Price: ${" "}
            {provider.hourlyPrice
              ? provider.hourlyPrice
              : getMinPackagePrice(JSON.parse(provider.Package))}
          </h6>
        </Col> */}
              </Row>
            </div>
            <div
              style={{
                height: "40px", // Fixing the height
                marginTop: "10px",
              }}
            >
              {provider.endName && (
                <p className="mb-0" style={{ fontSize: "12px" }}>
                  <span className="fw-bold">Recommeded by: </span>
                  {provider.endName.split(",").length}{" "}
                  {provider.endName.split(",").length === 1
                    ? "vendor"
                    : "vendors"}
                  &nbsp;in the wedding industry
                </p>
              )}
            </div>
          </CardBody>
          {/* </RibbonContainer> */}
        </Card>
      </Col>
    );
  }

  function needsInBwvGrid(need) {
    return (
      <Col key={need.Id} lg="4" className="position-relative">
        <Card
          style={{ cursor: "pointer", height: "100%" }}
          onClick={() => handleNeedCardClick(need)}
        >
          {need?.caIdStatus === 1 ? (
            <GitHubForkRibbon color="green" position="left">
              Verified & Trusted
            </GitHubForkRibbon>
          ) : null}
          <span
            style={{
              position: "absolute",
              top: "50%",
              left: "2%",
              fontWeight: "bold",
              color: "#fff",
            }}
          >
            {/* {provider.listName} */}
          </span>
          <BwvGridImage provider={need} />
          <p
            style={{
              position: "absolute",
              top: "40%",
              left: "50%",
              textWrap: "nowrap",
              color: "black",
              transform: "translateX(-50%)",
            }}
            className="bg-secondary text-white rounded-pill px-3 py-2 text-center"
          >
            {need?.categoryName}
          </p>
          <CardBody
            style={{
              maxHeight: "110px",
              overflow: "hidden",
              gap: 5,
              minHeight: "225px",
            }}
          >
            {/* <p>{provider.description}</p> */}
            <p
              style={{
                fontSize: "small",
                marginBottom: "0.5rem",
                marginTop: 0,
              }}
              className="mt-4 text-nowrap"
            >
              {need.Title}
            </p>
          </CardBody>
        </Card>
      </Col>
    );
  }
};

export default BwvGrid;
