/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useRef, useState, useEffect } from "react";
import "../LocalCommunityAds.css"; // Make sure to create a CSS file for styling
import "rc-slider/assets/index.css";
import {
  fetchItems,
  fetchSubCategories,
  fetchLocalCommunityAds,
  fetchCategories,
  fetchBWV,
} from "../../Data";
import { SearchLca } from "../../WeddingVendors/SearchLca";
import { useDispatch, useSelector } from "react-redux";
import {
  resetLca,
  setActiveTab,
  setAllProducts,
  setBizzLca,
  setBwv,
  setBwvLoading,
  setFinalBwv,
  setIsLoading,
  setLca,
  setLcaLoading,
  setMonth,
  setSelectedSubCategory,
} from "../../../../redux/slices/LcaSlice";
import store from "../../../../redux/store";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import RecentAds from "./RecentAds2";
import LcaSideBar from "./LcaSideBar2";
const LocalCommunityAds = () => {
  const fetchLcaCalled = useRef(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [productSubCat, setProductSubCat] = useState([]);
  const [items, setItems] = useState([]);
  const [scrollPopupVisible, setScrollPopupVisible] = useState(false);
  const { isLoading, finalProducts } = useSelector((state) => state.lcAds);
  const location = useLocation();
  const code = new URLSearchParams(location.search).get("code");
  useEffect(() => {
    if (code) {
      localStorage.setItem("code", code);
      navigate("/dashboardpage/settings");
    }
  }, [code]);
  const fetchData = async () => {
    dispatch(setLcaLoading(true));
    const category = await fetchCategories();
    const subCategories = await fetchSubCategories();
    const itemsData = await fetchItems();
    const filterCategoryByName = category.filter(
      (cat) => cat.Name === "Local Community Ads"
    );
    const filterSubCat = subCategories.filter(
      (subCat) => subCat.CategoryId === filterCategoryByName[0].Id
    );
    const filterItems = itemsData.filter((item) =>
      filterSubCat.some((subCat) => item.subCatId === subCat.id)
    );
    setProductSubCat(filterSubCat);
    setItems(filterItems);
    dispatch(setLcaLoading(false));
  };
  console.debug(finalProducts, "finalProducts");
  const fetchLCA = async () => {
    dispatch(setLcaLoading(true));
    let pageNumber = 1;
    let allAds = [];
    while (true) {
      const payload = { pageId: pageNumber };
      const ads = await fetchLocalCommunityAds(payload);
      allAds = [...allAds, ...ads];
      const existingAllProducts = store.getState().lcAds.allProducts;
      const existingLca = store.getState().lcAds.Lca;
      const existingIdsAllProducts = existingAllProducts.map(
        (product) => product.Id
      );
      const newProducts = ads.filter(
        (ad) => !existingIdsAllProducts.includes(ad.Id)
      );
      const filterNormalNeeds = newProducts.filter(
        (product) => product.engagedCouple !== 1
      );
      const existingIdsLca = existingLca.map((product) => product.Id);
      const newLca = ads.filter((ad) => !existingIdsLca.includes(ad.Id));
      dispatch(setAllProducts([...existingAllProducts, ...filterNormalNeeds]));
      dispatch(setLca([...existingLca, ...newLca]));
      if (ads.length < 50) break;
      pageNumber++;
    }
    dispatch(setBizzLca(allAds));
    dispatch(setLcaLoading(false));
  };
  const fetchBWVData = async () => {
    dispatch(setBwvLoading(true));
    const bwvData = await fetchBWV();
    const filterBwvData = bwvData.filter((vendor) => vendor.isactive === "3");
    dispatch(setBwv(filterBwvData));
    dispatch(setBwvLoading(false));
  };
  const fetchDataCalled = useRef(false);
  const fetchBWVDataCalled = useRef(false);

  useEffect(() => {
    if (!fetchDataCalled.current) {
      fetchData();
      fetchDataCalled.current = true;
    }
    if (!fetchLcaCalled.current) {
      fetchLCA();
      fetchLcaCalled.current = true;
    }
    if (!fetchBWVDataCalled.current) {
      fetchBWVData();
      fetchBWVDataCalled.current = true;
    }
    dispatch(setActiveTab("Customers"));
    dispatch(setSelectedSubCategory(null));
  }, []);
  const handleClear = () => {
    dispatch(resetLca());
    dispatch(setMonth(""));
  };

  return (
    <div className="local-community-ads-container bg-white">
      <div className="container">
        <SearchLca />
        <div className="row">
          <div
            className="col-lg-3 sidebar px-3"
            style={{
              overflow: "hidden",
              height: "100%",
            }}
            id="lca-sidebar"
          >
            <LcaSideBar
              isLoading={isLoading}
              items={items}
              productSubCat={productSubCat}
              scrollPopupVisible={scrollPopupVisible}
              setScrollPopupVisible={setScrollPopupVisible}
            />
          </div>
          <div className="col-lg-12 content col-xl-9">
            <RecentAds
              handleClear={handleClear}
              productSubCat={productSubCat}
              isLoading={isLoading}
              scrollPopupVisible={scrollPopupVisible}
              setScrollPopupVisible={setScrollPopupVisible}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocalCommunityAds;
