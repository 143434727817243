import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import PlaceHolder from "../../assets/images/Placeholder.png";
import {
  Row,
  Col,
  Container,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Table,
  Spinner,
} from "reactstrap";
import {
  fetchAllUsers,
  fetchLocalCommunityAds,
  fetchUserDetails,
} from "../../components/Header/Data";
import { useSelector } from "react-redux";
import LcaCards from "../../components/Header/LocalCummunityAds/LcaCards";
import { useParams } from "react-router-dom";
import { FadeLoader } from "react-spinners";
import { FaArrowLeft } from "react-icons/fa";
import moment from "moment";

const EngagedDetails = () => {
  const navigate = useNavigate();
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const [coupleNeeds, setCoupleNeeds] = useState([]);
  const [couple, setCouple] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null);
  const [loading, setLoading] = useState(false);
  const [scrollPopupVisible, setScrollPopupVisible] = useState(false);

  let { Id } = useParams();
  Id = parseInt(Id);

  // const selectedCard =
  //   couple && Id && couple.find((couple) => couple.Id === Id);
  // const eventDetails = selectedCard?.eventDetails
  //   ? JSON.parse(selectedCard?.eventDetails)
  //   : [];
  console.log(selectedCard, "selectedCard");
  const fetchData = async () => {
    setLoading(true);
    // const users = await fetchAllUsers();
    // const filterEngaged = users.filter((user) => user.EngagedCoupled === 1);
    // setCouple(filterEngaged);
    // setLoading(false);
    const Card = await fetchUserDetails(Id);
    setCouple(Card);
    setSelectedCard(Card);
    setLoading(false);
  };
  const eventDetails = selectedCard?.eventDetails
    ? JSON.parse(selectedCard?.eventDetails)
    : [];

  useEffect(() => {
    fetchData();
  }, [Id]);

  const fetchreq = async () => {
    const myNeeds = await fetchLocalCommunityAds();
    if (selectedCard) {
      const filterneeds = myNeeds.filter(
        (need) => need.createdBy === selectedCard.Id
      );
      setCoupleNeeds(filterneeds);
    }
  };

  useEffect(() => {
    fetchreq();
  }, [selectedCard]);

  function convertTo12HourFormat(time24) {
    if (!time24) {
      return "";
    }
    const [hour24, minute] = time24.split(":").map(Number);
    if (isNaN(hour24) || isNaN(minute)) {
      return "";
    }
    const period = hour24 >= 12 ? "PM" : "AM";
    const hour12 = (hour24 % 12 || 12).toString().padStart(2, "0");
    return `${hour12}:${minute.toString().padStart(2, "0")} ${period}`;
  }

  function formatDate(dateStr) {
    if (!dateStr || typeof dateStr !== "string") {
      return "";
    }
    const date = new Date(dateStr);
    const formattedDate = new Date(
      date.getTime() + date.getTimezoneOffset() * 60000
    )
      .toISOString()
      .split("T")[0];
    const [year, month, day] = formattedDate.split("-");
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const monthAbbrev = months[parseInt(month, 10) - 1];
    return `${monthAbbrev} ${day}, ${year}`;
  }

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <FadeLoader color={"#36D7B7"} />
      </div>
    );
  }

  const filteredEvents = eventDetails?.filter(
    (event) =>
      event?.eventDate ||
      event?.eventLocation ||
      event?.eventTime ||
      event?.eventType ||
      event?.totalGuestCount
  );

  return (
    <Container>
      {selectedCard ? (
        <Row className="my-5">
          <p
            onClick={() => {
              navigate(-1);
            }}
            style={{
              cursor: "pointer",
              // position: "absolute",
              top: "19px",
              left: "10%",
              fontSize: "18px",
              color: "#000",
              zIndex: 999,
            }}
            className="m-2 d-xl-none"
          >
            <FaArrowLeft
              style={{
                marginRight: "6px",
                fontSize: "18px",
                marginTop: "-3px",
              }}
            />
            Back
          </p>
          <Col md={4}>
            <Card>
              <img
                className="card-img-top"
                src={selectedCard?.Photo ? selectedCard.Photo : PlaceHolder}
                alt={selectedCard?.FirstName}
              />
              <CardBody>
                <CardTitle tag="h5">{selectedCard?.FirstName}</CardTitle>
                <CardText>
                  <i className="fa-solid fa-location-dot"></i>{" "}
                  {selectedCard?.ZipCode}
                </CardText>
              </CardBody>
            </Card>
          </Col>
          <Col md={8}>
            <Card className="mb-4">
              <CardBody>
                <CardTitle tag="h5">{selectedCard?.FirstName}</CardTitle>
                <CardText>Request &gt;&gt; Newly engaged couple</CardText>
                {filteredEvents.length > 0 ? (
                  <Table responsive className="table-light border">
                    <thead>
                      <tr>
                        <th>S.No.</th>
                        <th>Event Date</th>
                        <th>Event Time</th>
                        <th>Event Type</th>
                        <th>Event Location</th>
                        <th>Guest Count</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredEvents.map((event, i) => (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>
                            {moment(event.eventDate).format("MMMM DD YYYY")}
                          </td>
                          <td>
                            {moment(event.eventTime, "HH:mm").format("hh:mm A")}
                          </td>
                          <td>{event.eventType}</td>
                          <td>{event.eventLocation}</td>
                          <td>{event.totalGuestCount}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  <div className="text-center my-3">No Events</div>
                )}
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <CardTitle tag="h5">Needs/Requests</CardTitle>
                <LcaCards
                  allProducts={coupleNeeds}
                  loading={loading}
                  setLoading={setLoading}
                  scrollPopupVisible={scrollPopupVisible}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      ) : (
        <div className="text-center m-5 p-5">Not an Engaged User</div>
      )}
    </Container>
  );
};

export default EngagedDetails;
