import React, { useEffect, useState } from "react";
import { Row, Col, FormGroup, Input, Label } from "reactstrap";
import axiosClient from "../../../axios-client";
import { useDispatch, useSelector } from "react-redux";
import {
  setCategory,
  setCategoryId,
  setItem,
  setItemId,
  setListName,
  setSubCategory,
  setSubCategoryId,
  setServiceLocation,
  setCustomDetails,
} from "../../../redux/slices/ServiceInfo";

const ServiceCategories = ({ categories, subCategories1, items }) => {
  const dispatch = useDispatch();
  const serviceInfo = useSelector((state) => state.serviceInfo);
  const [subCategories, setSubCategories] = useState([]);
  const [ItemsbyId, setItemsByID] = useState([]);
  const [customDetailsCache, setCustomDetailsCache] = useState({});
  useEffect(() => {
    if (serviceInfo.categoryId) {
      const filteredSubCategories = subCategories1.filter(
        (subCategory) => subCategory.CategoryId === serviceInfo.categoryId
      );
      setSubCategories(filteredSubCategories);
    }
  }, [serviceInfo.categoryId, subCategories1]);
  useEffect(() => {
    if (serviceInfo.subCategoryId) {
      fetchItems(serviceInfo.subCategoryId);
    }
  }, [serviceInfo.subCategoryId]);
  const fetchItems = async (subCategoryId) => {
    try {
      const response = await axiosClient.get(
        `sub-category-items/getSubcategoryItembySubcat/${subCategoryId}`
      );
      setItemsByID(response.data.subCategoryItems);
    } catch (error) {
      console.error("Error fetching items:", error);
    }
  };
  console.log(serviceInfo, "serviceInfo");
  const handleChange = (e) => {
    const { name, value } = e.target;
    let updatedCategoryId = serviceInfo.categoryId;
    let updatedSubCategoryId = serviceInfo.subCategoryId;
    let updateditemId = serviceInfo.itemId;
    let updatedValue = value;
    if (name === "category") {
      setSubCategories([]);
      setItemsByID([]);
      dispatch(setCustomDetails({}));
      const selectedCategory = categories.find(
        (category) => category.Name === value
      );
      if (selectedCategory) {
        const filteredSubCategories = subCategories1.filter(
          (subCategory) => subCategory.CategoryId === selectedCategory.Id
        );
        setSubCategories(filteredSubCategories);
        updatedCategoryId = selectedCategory.Id;
        updatedSubCategoryId = null;
        updateditemId = null;
      } else {
        setSubCategories([]);
        updatedCategoryId = ""; // Reset categoryId if no category is selected
      }
      dispatch(setCategoryId(updatedCategoryId));
      dispatch(setCategory(selectedCategory.Name));
      dispatch(setSubCategoryId(null));
      dispatch(setSubCategory(""));
      dispatch(setItemId(null));
      dispatch(setItem(""));
    } else if (name === "subCategory") {
      setItemsByID([]);
      const selectedSubCat = subCategories.find(
        (subCat) => subCat.Name === value
      );
      if (selectedSubCat) {
        updatedSubCategoryId = selectedSubCat.id;
        updateditemId = null;
        fetchItems(updatedSubCategoryId);
      } else {
        setItemsByID([]);
        updatedSubCategoryId = ""; // Reset subCategoryId if no subcategory is selected
      }
      dispatch(setSubCategoryId(updatedSubCategoryId));
      dispatch(setSubCategory(selectedSubCat.Name));
      dispatch(setItemId(null));
      dispatch(setItem(""));
    } else if (name === "items") {
      const selectedItem = ItemsbyId.find(
        (item) => item.subCatItemName === value
      );
      updateditemId = selectedItem ? selectedItem.itemId : "";
      dispatch(setItemId(updateditemId));
      dispatch(setItem(selectedItem.subCatItemName));
    }
  };

  useEffect(() => {
    fetchItems(serviceInfo.subCategoryId);
  }, [serviceInfo.subCategoryId, items]);

  return (
    <div>
      <Row>
        <h6>Service Information</h6>
        <p className="pb-4">
          Set up your listing and choose one or more service types, such as
          hourly, monthly and flat-rate.
        </p>
        <Col xs={12} sm={4}>
          <FormGroup>
            <Label>
              Business Name <span style={{ color: "red" }}>*</span>
            </Label>
            <Input
              placeholder="Type your business name..."
              type="text"
              name="listName"
              value={serviceInfo.listName}
              onChange={(e) => dispatch(setListName(e.target.value))}
            />
          </FormGroup>
        </Col>
        <Col xs={12} sm={4}>
          <FormGroup>
            <Label>
              Business Location City <span style={{ color: "red" }}>*</span>
            </Label>
            <Input
              placeholder="Enter City Name"
              type="text"
              name="serviceLocation"
              value={serviceInfo.serviceLocation}
              onChange={(e) => dispatch(setServiceLocation(e.target.value))}
            />
          </FormGroup>
        </Col>
        <Col xs={12} sm={4}>
          <FormGroup>
            <Label>
              Category <span style={{ color: "red" }}>*</span>
            </Label>
            <Input
              placeholder="Select category"
              type="select"
              name="category"
              value={serviceInfo.category}
              onChange={handleChange}
            >
              <option value="">Select category</option>
              {categories.map((category) => (
                <option key={category.Id} value={category.Name}>
                  {category.Name}
                </option>
              ))}
            </Input>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={6}>
          <FormGroup>
            <Label>Sub Category</Label>
            <Input
              placeholder="Select sub category"
              type="select"
              name="subCategory"
              value={serviceInfo.subCategory}
              onChange={handleChange}
              disabled={subCategories.length === 0}
            >
              <option value="" disabled>
                {subCategories.length === 0 ? "" : "Select subcategory"}
              </option>
              {subCategories.map((subCategory) => (
                <option key={subCategory.id} value={subCategory.Name}>
                  {subCategory.Name}
                </option>
              ))}
            </Input>
          </FormGroup>
        </Col>
        <Col xs={12} sm={6}>
          <FormGroup>
            <Label>Items</Label>
            <Input
              placeholder="Select items"
              type="select"
              name="items"
              value={serviceInfo.item}
              onChange={handleChange}
              disabled={ItemsbyId.length === 0}
            >
              <option value="" disabled>
                {ItemsbyId.length === 0 ? "" : "Select items"}
              </option>
              {ItemsbyId.map((item) => (
                <option key={item.ID} value={item.subCatItemName}>
                  {item.subCatItemName}
                </option>
              ))}
            </Input>
          </FormGroup>
        </Col>
      </Row>
    </div>
  );
};

export default ServiceCategories;
