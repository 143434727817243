/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Input, Button } from "reactstrap";
import BwvSideBar from "./BwvSideBar";
import { useLocation, useNavigate } from "react-router-dom";
import "./BrowseWeddingVendors.css";
import BwvContent from "./BwvContent";
import { SearchBwv } from "./SearchBwv";

import {
  fetchCategories,
  fetchSubCategories,
  fetchItems,
  fetchBWV,
  fetchOptionsList,
  fetchOptions,
  fetchLocalCommunityAds,
} from "../Data";
import { useDispatch, useSelector } from "react-redux";
import {
  setBwvSearchQuery,
  setFinalBwv,
  setFinalNeeds,
  setOriginalBwv,
  setOriginalNeeds,
  setSearchTerm,
  setSelectedCat,
  setSelectedOption,
  setSelectedOptionData,
  setSelectedQuary,
} from "../../../redux/slices/BwvData";
import store from "../../../redux/store";

const BrowseWeddingVendors = () => {
  const fetchLcaCalled = useRef(false);
  const [isReset, setIsReset] = useState(false);
  const [categories, setCategories] = useState([]);
  const location = useLocation();
  const [subCategories, setSubCategories] = useState([]);
  const [items, setItems] = useState([]);
  const [listOptions, setListOptions] = useState([]);
  const [optionsData, setOptionsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { finalBwv, originalBwv, searchTerm, originalNeeds } = useSelector(
    (state) => state.bwvData
  );
  const dispatch = useDispatch();
  const handleclear = () => {
    dispatch(setSearchTerm(""));
    dispatch(setFinalBwv(originalBwv));
    dispatch(setFinalNeeds(originalNeeds));
    setIsReset(true);
    dispatch(setSelectedQuary(null));
    dispatch(setBwvSearchQuery(""));
    dispatch(setSelectedOption(null));
    dispatch(setSelectedOptionData(null));
  };

  const fetchOptionsData = async () => {
    const options = {};
    for (const item of finalBwv) {
      const listData = await fetchOptionsList(item.categoryId);
      setListOptions((prevListOptions) => [...prevListOptions, listData]);
      for (const option of listData) {
        const data = await fetchOptions(option.cat_list_id);
        options[option.cat_list_id] = data;
      }
    }
    setOptionsData(options);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchOptionsData();
  }, []);
  const fetchData = async () => {
    setIsLoading(true);
    const categoriesData = await fetchCategories();
    const subCategoriesData = await fetchSubCategories();
    const itemsData = await fetchItems();
    const bwvData = await fetchBWV();
    const filterBwvData = bwvData.filter((vendor) => vendor.isactive === "3");
    setCategories(categoriesData);
    setSubCategories(subCategoriesData);
    setItems(itemsData);
    if (location.state && location.state.cat) {
      dispatch(setSelectedCat(location.state.cat));
    }
    dispatch(setOriginalBwv(filterBwvData));
    setIsLoading(false);
    window.scrollTo(0, 0);
  };
  const fetchLCA = async () => {
    let pageNumber = 1;
    let allAds = [];
    while (true) {
      const payload = { pageId: pageNumber, subCategoryId: 63 };
      const ads = await fetchLocalCommunityAds(payload);
      allAds = [...allAds, ...ads];
      const existingAllNeeds = store.getState().bwvData.originalNeeds;
      const existingIdsAllNeeds = existingAllNeeds.map((product) => product.Id);
      const newProducts = ads.filter(
        (ad) => !existingIdsAllNeeds.includes(ad.Id)
      );
      const filterNormalNeeds = newProducts.filter(
        (product) => product.engagedCouple !== 1
      );
      dispatch(setOriginalNeeds([...existingAllNeeds, ...filterNormalNeeds]));
      if (ads.length < 50) break;
      pageNumber++;
    }
  };
  useEffect(() => {
    if (!fetchLcaCalled.current) {
      fetchLCA();
      fetchLcaCalled.current = true;
    }
  }, []);
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (isReset && searchTerm === "") {
      setIsReset(false);
      navigate("/weddingvendors");
    }
  }, [isReset, searchTerm]);

  return (
    <div className="bg-white">
      <div className="container">
        <Row className="Browse-wedding-vendors-container">
          <SearchBwv />
          <Col sm={3} className="bwv-sidebar mt-4" id="bwv-sidebar">
            {" "}
            <BwvSideBar
              searchTerm={searchTerm}
              categories={categories}
              subCategories={subCategories}
              items={items}
              listOptions={listOptions}
              optionsData={optionsData}
              selectCat={location.state}
              isReset={isReset}
            />
          </Col>
          <Col lg={9}>
            <BwvContent
              handleclear={handleclear}
              listOptions={listOptions}
              optionsData={optionsData}
              isLoading={isLoading}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default BrowseWeddingVendors;
