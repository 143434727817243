import React from "react";
import userIcon from "../../assets/images/ext/user-profile.jpg";
import { Button, Col, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { FaFacebook } from "react-icons/fa";
import Facebook from "../../assets/images/smLogos/facebook.png";
import Instagram from "../../assets/images/smLogos/instagram.png";
import Yelp from "../../assets/images/smLogos/yelp.png";
import ProfileMaps from "./ProfileMaps";
import Maps from "../DashboardPages/Maps";
import {
  setConnectionModal,
  setOpenJobModal,
} from "../../redux/slices/PeopleInComSlice";
import { useDispatch } from "react-redux";

const PeopleProfileDetails = ({ userDetails, setMsgModalOpen }) => {
  const dispatch = useDispatch();
  const [city, setCity] = React.useState("");
  return (
    <div>
      <div className="people-profile-img">
        <img
          className="w-75"
          src={
            userDetails.Photo && userDetails.Photo !== ""
              ? userDetails.Photo
              : userIcon
          }
        />
      </div>
      <h6 className="text-center text-nowrap">
        {userDetails.FirstName} {userDetails.LastName}
      </h6>
      <p className="text-center text-nowrap">{userDetails.Location}</p>
      {userDetails.phoneShow == 1 && userDetails.dialExt !== 0 && (
        <a
          href={`tel:8002453610`}
          className="btn btn-success btn-block mb-2 d-flex justify-content-center align-items-center"
        >
          Call: (800) 245-3610 <br />
          <span>Extension # {userDetails.dialExt}</span>
        </a>
      )}
      <Row className="justify-content-center">
        <Col xs="4" className="px-1">
          <Button
            color="primary"
            size="sm"
            className="w-100"
            style={{ fontSize: "x-small" }}
            onClick={() => setMsgModalOpen(true)}
          >
            Send Message
          </Button>
        </Col>
        <Col xs="4" className="px-1">
          <Button
            color="primary"
            size="sm"
            className="w-100"
            style={{ fontSize: "x-small" }}
            onClick={() => dispatch(setOpenJobModal(true))}
          >
            Send Job Opportunity
          </Button>
        </Col>
        <Col xs="4" className="px-1">
          <Button
            color="primary"
            size="sm"
            className="w-100"
            style={{ fontSize: "x-small" }}
            onClick={() => dispatch(setConnectionModal(true))}
          >
            Request Connection
          </Button>
        </Col>
      </Row>
      <div className="sm-accounts d-flex mt-3 justify-content-center">
        <Link
          to={userDetails.facebookUrl}
          target="_blank"
          rel="noopener noreferrer"
          className="social-icon mx-2"
        >
          <img
            src={Facebook}
            alt="facebook"
            className="img-fluid d-block"
            style={{ cursor: "pointer", width: "40px", height: "40px" }}
          />
        </Link>
        <Link
          to={userDetails.instagramUrl}
          target="_blank"
          rel="noopener noreferrer"
          className="social-icon mx-2"
        >
          <img
            src={Instagram}
            alt="instagram"
            className="img-fluid d-block"
            style={{ cursor: "pointer", width: "40px", height: "40px" }}
          />
        </Link>
        <Link
          to={userDetails.yelpUrl}
          target="_blank"
          rel="noopener noreferrer"
          className="social-icon mx-2"
        >
          <img
            src={Yelp}
            alt="yelp"
            className="img-fluid d-block"
            style={{ cursor: "pointer", width: "40px", height: "40px" }}
          />
        </Link>
      </div>
      <Maps zipCode={userDetails.ZipCode} setCity={setCity} />
    </div>
  );
};

export default PeopleProfileDetails;
