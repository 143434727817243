import React, { useState } from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import TempImages from "./TempImages";

const AdminImages = ({
  highlightedImages,
  setHighlightedImages,
  fetchImages,
}) => {
  const [approvedImagesExpanded, setApprovedImagesExpanded] = useState(false);
  const [pendingImagesExpanded, setpendingImagesExpanded] = useState(false);
  let pending =
    highlightedImages &&
    highlightedImages.filter((image) => image.image_status === 0);

  let approved =
    highlightedImages &&
    highlightedImages.filter((image) => image.image_status === 1);
  return (
    <div className="category-container d-flex flex-column gap-2 inter-font">
      <div
        className="category-header"
        onClick={() => setApprovedImagesExpanded(!approvedImagesExpanded)}
      >
        <h6
          className={`ps-3 d-flex justify-content-between p-3 rounded border border-secondary text-secondary bg-outline-secondary ${
            approvedImagesExpanded ? "active text-white bg-secondary" : ""
          }`}
          style={{ cursor: "pointer", transition: "all 0.2s ease-in-out" }}
        >
          Approved Images
          {approvedImagesExpanded ? (
            <FaAngleUp size={20} />
          ) : (
            <FaAngleDown size={20} />
          )}
        </h6>
      </div>
      {approvedImagesExpanded && (
        <>
          {approved.length > 0 ? (
            <TempImages
              highlightedImages={approved}
              setHighlightedImages={setHighlightedImages}
              fetchImages={fetchImages}
            />
          ) : (
            <h6 className="text-center m-5">No Approved Images</h6>
          )}
        </>
      )}

      <div
        className="category-header"
        onClick={() => setpendingImagesExpanded(!pendingImagesExpanded)}
      >
        <h6
          className={`ps-3 d-flex justify-content-between p-3 rounded border border-secondary text-secondary bg-outline-secondary ${
            pendingImagesExpanded ? "active text-white bg-secondary" : ""
          }`}
          style={{ cursor: "pointer", transition: "all 0.2s ease-in-out" }}
        >
          Pending Images
          {pendingImagesExpanded ? <FaAngleUp /> : <FaAngleDown />}
        </h6>
      </div>
      {pendingImagesExpanded && (
        <>
          {pending.length > 0 ? (
            <TempImages
              highlightedImages={pending}
              setHighlightedImages={setHighlightedImages}
              fetchImages={fetchImages}
            />
          ) : (
            <h6 className="text-center m-5">No Pending Images</h6>
          )}
        </>
      )}
    </div>
  );
};

export default AdminImages;
