import React, { useState } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { useSelector } from "react-redux";

const BwvMedia = () => {
  const selectedCard = useSelector((state) => state.bwvData.selectedService);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  // Parse the Photo JSON string into an array
  const photoArray = selectedCard?.Photo ? JSON.parse(selectedCard.Photo) : [];

  const openLightbox = (index) => {
    setSelectedImageIndex(index);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
    setSelectedImageIndex(null);
  };

  const goToNextImage = () => {
    setSelectedImageIndex((prevIndex) => (prevIndex + 1) % photoArray.length);
  };

  const goToPreviousImage = () => {
    setSelectedImageIndex(
      (prevIndex) => (prevIndex - 1 + photoArray.length) % photoArray.length
    );
  };

  const stopPropagation = (event) => {
    event.stopPropagation();
  };

  return (
    <div>
      <h6 className="pb-4 text-center fw-bold">Highlight Images</h6>
      {photoArray.length > 0 ? (
        <div style={{ position: "relative" }}>
          <div className="bwv-image-container">
            {photoArray.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`Media ${index}`}
                className="bwv-image"
                onClick={() => openLightbox(index)}
              />
            ))}
          </div>

          {lightboxOpen && (
            <div
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.7)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={closeLightbox} // Close lightbox on click outside
            >
              <button
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "10px",
                  transform: "translateY(-50%)",
                  zIndex: 1,
                  backgroundColor: "transparent",
                  border: "none",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  goToPreviousImage();
                }}
              >
                <FaChevronLeft style={{ color: "#fff", fontSize: 24 }} />
              </button>
              <img
                src={photoArray[selectedImageIndex]}
                alt="Selected"
                style={{
                  maxHeight: "90%",
                  maxWidth: "90%",
                  objectFit: "contain",
                }}
                onClick={stopPropagation}
              />
              <button
                style={{
                  position: "absolute",
                  top: "50%",
                  right: "10px",
                  transform: "translateY(-50%)",
                  zIndex: 1,
                  backgroundColor: "transparent",
                  border: "none",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  goToNextImage();
                }}
              >
                <FaChevronRight style={{ color: "#fff", fontSize: 24 }} />
              </button>
            </div>
          )}
        </div>
      ) : (
        <p className="text-center">No Media Available.</p>
      )}
    </div>
  );
};

export default BwvMedia;
