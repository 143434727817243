import React, { useEffect, useState } from "react";

import { AiFillEye } from "react-icons/ai";
import { MdOutlineAccessTime } from "react-icons/md";
import { BsX } from "react-icons/bs";
import "bootstrap/dist/css/bootstrap.min.css";
import "./ProfileSidebar.css"; // Custom CSS
import { FaUserFriends, FaFileImage } from "react-icons/fa";
import { CgPaypal } from "react-icons/cg";
import {
  Button,
  Collapse,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  makeCall,
  postOrder,
  sendMsgMail,
  twilioToken,
  virtualTourViewProvider,
} from "../../../components/Header/Data";
import { toast } from "react-toastify";
import OfferDetails from "./VendorOffers/OfferDetails";
import OfferStatus from "./VendorOffers/OfferStatus";
import { GetZoomLink } from "../../../components/Header/Data2";
import ContactTemplates from "./ContactTemplates";
import {
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
} from "reactstrap";
import { setDescription, setOrder } from "../../../redux/slices/MsgSlice";
import Payment from "../settingsComponents/Payment";
import AppointmentStatus from "./Appointments/AppointmentStatus";
import QuoteResponces from "./Appointments/QuoteResponses";
import QuoteCards from "./Appointments/QuoteCards";
import { FaImage } from "react-icons/fa";
import { FaRegFileVideo } from "react-icons/fa";
import ImageContainer from "./ImageContainer";
import VideoContainer from "./VideoContainer";

const UserContact = ({ fetchMsgs, fetchAllMsg }) => {
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const navigate = useNavigate();
  const { selectedConvo, temps, description, orderStatus } = useSelector(
    (state) => state.messages
  );
  const customerTimezone = selectedConvo?.customerTimezone?.split(",")[0];
  const providerTimezone = selectedConvo?.providerTimezone?.split(",")[0];
  const result = getTimeDifference(customerTimezone, providerTimezone);
  const [tourData, setTourData] = useState({});
  const logUser = localStorage.getItem("USER_ROLE");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [amount, setAmount] = useState(null);
  const [openPaypal, setOpenPaypal] = useState(false);
  const dispatch = useDispatch();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownToggle = () => setDropdownOpen((prevState) => !prevState);
  const [isReady, setIsReady] = useState(false);
  const [isInCall, setIsInCall] = useState(false);
  const [error, setError] = useState(null);
  const [device, setDevice] = useState(null);
  const [viewAppointment, setViewAppointment] = useState(false);
  const [collapse, setCollapse] = React.useState({
    messages: false,
    people: true,
    quotes: true,
    templates: false,
    files: false,
    notepad: false,
    chatPayment: false,
    images: false,
    videos: false,
  });
  let templates =
    temps && temps.filter((template) => template.createdBy == user.UserID);
  const viewProfile = (msg) => {
    if (msg.createdBy === user.UserID) {
      navigate(`/userDetails/${msg.customerId}`, { state: msg.ownerId });
    } else {
      navigate(`/userDetails/${msg.createdBy}`, { state: msg.ownerId });
    }
  };
  const handleTemplateClick = () => {
    setCollapse((prevState) => ({
      ...prevState,
      templates: !prevState.templates,
    }));
  };

  const fecthvirtualTourViewProvider = async () => {
    let formData = {
      needId: selectedConvo?.postId,
      userId: user?.UserID,
    };
    const tourData = await virtualTourViewProvider(formData);
    setTourData(tourData[0]);
  };

  React.useEffect(() => {
    if (selectedConvo?.postType === 3) {
      fecthvirtualTourViewProvider();
    }
  }, [selectedConvo]);

  function formatDate1(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}`;
  }
  const now = new Date();
  const formattedDate = formatDate1(now);

  function getTimeDifference(customerTimezone, providerTimezone) {
    const customerDate = new Date().toLocaleString("en-US", {
      timeZone: customerTimezone,
    });
    const customerTime = new Date(customerDate);
    const providerDate = new Date().toLocaleString("en-US", {
      timeZone: providerTimezone,
    });
    const providerTime = new Date(providerDate);
    const customerToProviderDiff =
      (providerTime - customerTime) / (1000 * 60 * 60);
    const options = { hour: "numeric", minute: "numeric", hour12: true };
    const formattedCustomerTime = new Intl.DateTimeFormat(
      "en-US",
      options
    ).format(customerTime);
    const formattedProviderTime = new Intl.DateTimeFormat(
      "en-US",
      options
    ).format(providerTime);
    const customerOffset = customerTime.getTimezoneOffset() / -60;
    const providerOffset = providerTime.getTimezoneOffset() / -60;
    const finalOutput = `
        ${formattedCustomerTime} GMT${
      customerOffset >= 0 ? "+" : ""
    }${customerOffset} (${Math.abs(customerToProviderDiff)} h ahead),
        ${formattedProviderTime} GMT${
      providerOffset >= 0 ? "+" : ""
    }${providerOffset} (${Math.abs(-customerToProviderDiff)} h behind)
    `;

    return finalOutput.trim();
  }

  // Example usage with Pacific Time and London
  //console.log(tourData, "tourData");
  const handleClick = async () => {
    const tourDetails = [
      {
        email: user?.Email,
        phoneNumber: user?.Phone,
        countryCode: user?.CountryCode,
      },
      {
        email: "",
        phoneNumber:
          tourData?.alternativePhoneOne === null ||
          tourData?.alternativePhoneOne === ""
            ? ""
            : tourData?.alternativePhoneOne,
        countryCode:
          tourData?.alternativePhoneOne === null ||
          tourData?.alternativePhoneOne === ""
            ? ""
            : tourData?.needCountryCode,
      },
      {
        email: "",
        phoneNumber:
          tourData?.alternativePhoneTwo === null ||
          tourData?.alternativePhoneTwo === ""
            ? ""
            : tourData?.alternativePhoneTwo,
        countryCode:
          tourData?.alternativePhoneTwo === null ||
          tourData?.alternativePhoneTwo === ""
            ? ""
            : tourData?.needCountryCode,
      },
    ];
    // needPhone;
    // alternativePhoneOne;
    // alternativePhoneTwo;
    // needEmail;
    // needCountryCode;
    // needUserPhone;
    let newPerson2;
    let newPerson3;
    if (tourData?.needPhone === tourData?.needUserPhone) {
      newPerson2 = {
        email: tourData?.needEmail, // Assuming anotherUser is defined
        phoneNumber: tourData?.needUserPhone,
        countryCode: tourData?.needCountryCode,
      };
    } else {
      newPerson2 = {
        email: tourData?.needEmail, // Assuming anotherUser is defined
        phoneNumber: tourData?.needUserPhone,
        countryCode: tourData?.needCountryCode,
      };
      newPerson3 = {
        email: "", // Assuming anotherUser is defined
        phoneNumber: tourData?.needPhone,
        countryCode: tourData?.needCountryCode,
      };
    }
    tourDetails.push(newPerson2);
    if (newPerson3) {
      tourDetails.push(newPerson3);
    }
    const stringifiedPeople = JSON.stringify(tourDetails);
    let formData = {
      email: user?.Email,
      phoneNumber: user?.Phone,
      countryCode: user?.CountryCode,
      phoneDetails: stringifiedPeople,
    };
    const response = await makeCall(formData);
    toast.success(
      "We will be sending you a text message link to join the meeting within 30 seconds"
    );
    if (response === "initiating call") {
      const formData = {
        startTime: formattedDate,
        needName: selectedConvo?.postName,
      };
      const linkResponse = await GetZoomLink(formData, selectedConvo?.Id);
      let formData1 = {
        zoomlink: linkResponse.join_url,
        phoneDetails: stringifiedPeople,
        customerId: user?.UserID,
        ownerId: tourData?.needCreatedBy,
        Type: "inbox",
        postId: selectedConvo?.postId,
        postName: selectedConvo?.postName,
      };
      await sendMsgMail(formData1);
    } else {
      throw new Error("Response from makeCall was invalid");
    }
  };
  const toggle = (section) => {
    setCollapse((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };
  const closeModal = () => {
    setModalIsOpen(false);
    setAmount(null);
    setOpenPaypal(false);
    dispatch(setDescription(""));
  };

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
    // setOpenPaypal(false);
  };

  // useEffect(() => {
  //   if (selectedConvo) {
  //     setAmount(parseInt(selectedConvo?.totalAmount));
  //     dispatch(setDescription(selectedConvo?.orderMessage));
  //   }
  // }, [selectedConvo?.orderMessage]);

  useEffect(() => {
    if (orderStatus === "COMPLETED") {
      setModalIsOpen(false);
    }
  }, [orderStatus]);

  const handlePayment = async () => {
    if (!description) {
      toast.error("Please Enter Description");
      return;
    }
    if (!amount) {
      toast.error("Please Enter Amount");
      return;
    }
    let formData = {
      customerId: user?.UserID,
      totalAmount: amount,
      Message: description,
      orderType: 3,
      messageId: selectedConvo?.Id,
      orderStatus: "PENDING",
    };
    const order = await postOrder(formData);
    //console.log(order?.Id, "Orders");
    dispatch(setOrder(order));
    toast.success("Order Created");
    setOpenPaypal(true);
  };

  const handleChatPayment = async () => {
    setModalIsOpen(true);
    //console.log(selectedConvo?.orderId, "selectedConvo?.orderId");
    dispatch(setOrder(selectedConvo?.orderId));
  };

  const handleCallButtonClick = async () => {
    const {
      needPhone,
      needUserPhone,
      alternativePhoneOne,
      alternativePhoneTwo,
      needCountryCode,
    } = tourData;

    const phoneNumbers = [
      needPhone,
      needUserPhone,
      alternativePhoneOne,
      alternativePhoneTwo,
    ].filter((phone) => phone !== ""); // Filters out any empty string
    // console.log(phoneNumbers, "phoneNumbers");
    const phoneNumbersWithCountryCode = phoneNumbers.map(
      (phone) => `${needCountryCode}${phone}`
    ); // Map the country code to each phone number

    console.log(phoneNumbersWithCountryCode, "phoneNumbersWithCountryCode");

    // Fetch the token from your server
    try {
      const response = await twilioToken("111111");
      // console.log(response.token, "Token"); // Correct way to access the token
      // Initialize Twilio Voice Client with the token
      const newDevice = await new window.Twilio.Device(response.token);
      setDevice(newDevice); // Save the device instance
      // console.log(newDevice, "newDevice");
      newDevice.on("ready", () => {
        // console.log("Twilio device is ready!");
        setIsReady(true);
      });

      newDevice.on("error", (error) => {
        console.error("Twilio error:", error);
        setError(error.message);
      });
      const roomName = "room1";
      // Initiate the call and add users to the conference room
      phoneNumbersWithCountryCode.map(async (phoneNumber) => {
        await newDevice.connect({
          params: JSON.stringify({
            To: phoneNumber, // The phone number to dial`, // This makes the user join the conference room
            callerId: user?.TwilioNumber
              ? `+1${user?.TwilioNumber}`
              : process.env.REACT_APP_TWILIO_NUMBER, // The caller ID to display
            intent: "dial", // Intent for dialing the call
          }),
        });
      });

      setIsInCall(true); // Set call status to true
      // console.log("Making a call...");
    } catch (error) {
      console.error("Error during call initiation:", error);
      setError(error.message);
    }
  };

  const handleDisconnectButtonClick = () => {
    if (device) {
      device.disconnectAll(); // Disconnect all active calls
      setIsInCall(false); // Update the call status to false
      // console.log("Call disconnected");
    }
  };

  return (
    <>
      {/* User Profile Section */}
      <div className="text-center">
        <img
          src={
            selectedConvo?.createdBy !== user?.UserID
              ? selectedConvo?.senderPhoto && selectedConvo.senderPhoto.trim()
                ? selectedConvo.senderPhoto
                : "https://cdn.pixabay.com/photo/2018/11/13/21/43/avatar-3814049_1280.png"
              : selectedConvo?.receiverProfilePhoto &&
                selectedConvo.receiverProfilePhoto.trim()
              ? selectedConvo.receiverProfilePhoto
              : "https://cdn.pixabay.com/photo/2018/11/13/21/43/avatar-3814049_1280.png"
          }
          alt="profile"
          className="rounded-circle mb-2"
          width={65}
        />
        <h5>
          {selectedConvo?.createdBy !== user?.UserID
            ? selectedConvo?.senderName
            : selectedConvo?.customerName}
        </h5>
        <div className="d-flex align-items-center justify-content-center text-muted">
          {selectedConvo?.customerTimezone !== null &&
            selectedConvo?.providerTimezone !== null && (
              <small>
                <MdOutlineAccessTime className="me-1" />
                {selectedConvo?.createdBy === user?.UserID
                  ? result.split(",")[0]
                  : result.split(",")[1]}
              </small>
            )}
        </div>
      </div>
      {/* View Contract Button */}
      <div
        className="text-center my-3"
        onClick={() => viewProfile(selectedConvo)}
      >
        <button className="btn btn-outline-success">
          <AiFillEye className="me-1" />
          View Contact
        </button>
      </div>
      <ListGroup flush className="position-relative">
        {selectedConvo.postType == 3 && (
          <div className="mb-3">
            <h6 className="text-dark text-center">Offer Status:</h6>
            <OfferStatus msg={selectedConvo} />
          </div>
        )}
        {selectedConvo.postType == 2 && (
          <div className="mb-3">
            <h6 className="text-dark text-center">Appointment Status:</h6>
            <AppointmentStatus msg={selectedConvo} />
          </div>
        )}
        {tourData &&
          tourData?.statusvalue === 1 &&
          tourData?.venueToCustomer === 1 &&
          selectedConvo?.createdBy === user?.UserID &&
          selectedConvo?.postType === 3 && (
            <>
              <div className="mb-1 text-center">
                <Dropdown isOpen={dropdownOpen} toggle={dropdownToggle}>
                  <DropdownToggle caret color="success">
                    Start Virtual Tour
                  </DropdownToggle>
                  <DropdownMenu style={{ marginLeft: "-10px" }}>
                    <DropdownItem
                      onClick={handleCallButtonClick}
                      style={{ fontSize: "14px" }}
                    >
                      Continue via VOIP
                    </DropdownItem>
                    <DropdownItem
                      onClick={handleClick}
                      style={{ fontSize: "14px" }}
                    >
                      Continue via Mobile Device
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
              <div className="text-center">
                {isInCall && <div className="my-2">Calling Customer...</div>}

                {isInCall && (
                  <button
                    onClick={handleDisconnectButtonClick}
                    className="btn btn-danger my-2"
                  >
                    Disconnect Call
                  </button>
                )}
              </div>
            </>
          )}

        {selectedConvo.postType == 3 && (
          <>
            <ListGroupItem tag="button" action onClick={() => toggle("people")}>
              <FaUserFriends className="me-2" /> Offer Details
            </ListGroupItem>
            <Collapse isOpen={collapse.people}>
              <div className="my-2 d-flex justify-content-center">
                <OfferDetails fetchMsgs={fetchMsgs} fetchAllMsg={fetchAllMsg} />
              </div>
            </Collapse>
          </>
        )}

        {selectedConvo.postType == 2 && (
          <>
            <ListGroupItem tag="button" action onClick={() => toggle("quotes")}>
              <FaUserFriends className="me-2" /> Appointments
            </ListGroupItem>
            <Collapse isOpen={collapse.quotes}>
              <div className="my-2 d-flex justify-content-center">
                <button
                  className="btn btn-primary btn-sm text-nowrap m-0"
                  // onClick={(event) => ViewOffer(event, msg)}
                  onClick={() => setViewAppointment(true)}
                >
                  View Appointment
                </button>{" "}
              </div>
              <QuoteCards
                open={viewAppointment}
                close={() => setViewAppointment(false)}
                fetchData={fetchMsgs}
                fetchAllMsg={fetchAllMsg}
              />
            </Collapse>
          </>
        )}

        <ListGroupItem tag="button" action onClick={() => toggle("templates")}>
          <FaFileImage className="me-2" /> Templates
        </ListGroupItem>
        <Collapse isOpen={collapse.templates}>
          <ContactTemplates templates={templates} />
        </Collapse>
        {logUser === "provider" && (
          <ListGroupItem tag="button" action onClick={() => toggle("images")}>
            <FaImage className="me-2" /> Images
          </ListGroupItem>
        )}
        <Collapse isOpen={collapse.images}>
          <div className="">
            <ImageContainer fetchMsgs={fetchMsgs} fetchAllMsg={fetchAllMsg} />
          </div>
        </Collapse>

        {logUser === "provider" && (
          <ListGroupItem tag="button" action onClick={() => toggle("videos")}>
            <FaRegFileVideo className="me-2" /> Videos
          </ListGroupItem>
        )}
        <Collapse isOpen={collapse.videos}>
          <div className="">
            <VideoContainer fetchMsgs={fetchMsgs} fetchAllMsg={fetchAllMsg} />
          </div>
        </Collapse>

        {/* {selectedConvo?.orderStatus !== "COMPLETED" &&
          selectedConvo?.orderType === 3 &&
          selectedConvo?.createdBy === user?.UserID && (
            <>
              <ListGroupItem
                tag="button"
                action
                onClick={() => toggle("chatPayment")}
              >
                <CgPaypal className="me-2" /> Chat Payment
              </ListGroupItem>

              <Collapse isOpen={collapse.chatPayment}>
                <div className="my-2 d-flex justify-content-center">
                  <Button size="sm" color="primary" onClick={handleChatPayment}>
                    Pay Now
                  </Button>
                </div>
              </Collapse>
            </>
          )} */}
      </ListGroup>
      <Modal isOpen={modalIsOpen} toggle={closeModal}>
        <ModalBody>
          {/* <div>
              <Label>Title</Label>
              <Input
                type="text"
                name="title"
                placeholder="Title"
                value={title}
                onChange={(e) => dispatch(setTitle(e.target.value))}
              />
            </div> */}
          <div>
            <Label>
              Description <b className="text-danger">*</b>
            </Label>
            <Input
              type="textarea"
              name="descrption"
              placeholder="Please Enter Descrption"
              value={description}
              // onChange={(e) => dispatch(setDescription(e.target.value))}
              disabled
            />
          </div>
          <div>
            <Label>Amount</Label>
            <InputGroup>
              <InputGroupText className="fw-bold">$</InputGroupText>
              <Input
                type="number"
                name="amount"
                placeholder="amount"
                value={amount}
                // onChange={handleAmountChange}
                disabled
              />
            </InputGroup>
            <div>
              <Payment
                totalPrice={Number(amount)}
                state={{ chatPay: true }}
                chatDetails={true}
              />
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default UserContact;
