import React, { useState } from "react";
import {
  Row,
  Col,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { CiMenuKebab } from "react-icons/ci";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";
import { FadeLoader } from "react-spinners";
import UpdateUserModal from "./UpdateUserModal";
import { useDispatch, useSelector } from "react-redux";
import { DeleteUser } from "../../components/Header/Data2";
import { toast } from "react-toastify";
import GitHubForkRibbon from "react-github-fork-ribbon";
import UserPlaceHolder from "../../assets/images/user-profile-icon.jpg";
import {
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  CardActions,
  Avatar,
  IconButton,
  Typography,
  Grid,
  Badge,
} from "@mui/material";
import { Edit, Delete, PeopleAltOutlined } from "@mui/icons-material";
import CustomerGottonModal from "./CustomerGottonModal";
import { setExtRequestFilter } from "../../redux/slices/UserStateSlice";

const UserCard = ({ fetchUsers }) => {
  const userinfo = useSelector((state) => state.user.userInfo);
  const islogin = useSelector((state) => state.user.isLogin);
  const user = islogin ? JSON.parse(userinfo) : null;
  const [openMenuId, setOpenMenuId] = useState(null);
  const usersPerPage = 9;
  const [currentPage, setCurrentPage] = useState(1);
  const [openUpdateUserModal, setOpenUpdateUserModal] = useState(false);
  const [userToUpdate, setUserToUpdate] = useState(null);
  const [openGottonModal, setOpenGottenModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const { finalUsers, extRequestFilter } = useSelector(
    (state) => state.userState
  );
  const totalPages = Math.ceil(finalUsers.length / usersPerPage);
  const dispatch = useDispatch();

  if (!finalUsers || finalUsers.length === 0) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: "200px",
        }}
      >
        <FadeLoader color="#36d7b7" />
      </div>
    );
  }

  const toggleMenu = (adId) => {
    setOpenMenuId(openMenuId === adId ? null : adId);
  };

  const onDelete = async (deleteUser) => {
    const reqData = {
      DeletedBy: user.UserID,
    };
    await DeleteUser(deleteUser.Id, reqData);
    toast.info(`User Deleted`);
    setOpenMenuId(null);
    fetchUsers();
  };

  const onEdit = (user) => {
    setUserToUpdate(user);
    setOpenUpdateUserModal(true);
  };
  const onCustomersGotten = (user) => {
    setSelectedUser(user);
    setOpenGottenModal(true);
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      paginate(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      paginate(currentPage + 1);
    }
  };

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = finalUsers.slice(indexOfFirstUser, indexOfLastUser);
  const truncateTitle = (title) => {
    return title.length > 15 ? title.substring(0, 15) + "..." : title;
  };
  const onRequestsClick = () => {
    dispatch(setExtRequestFilter(!extRequestFilter));
  };
  console.log(currentUsers, "currentUsers");
  return (
    <div className="user-manage-Container p-3">
      <div className="d-flex justify-content-end pb-3">
        <button
          className={`btn ${extRequestFilter ? "btn-danger" : "btn-primary"}`}
          onClick={onRequestsClick}
        >
          {extRequestFilter ? "Show All Users" : "Show Extension Requests"}
        </button>
      </div>
      <Grid container spacing={3} className="user-manage-grid">
        {currentUsers.map((user) => (
          <Grid item xs={12} sm={6} md={4} key={user.Id}>
            <Card
              sx={{
                maxWidth: 345,
                height: "100%", // Ensures all cards have the same height
                display: "flex",
                flexDirection: "column", // Ensures proper layout with dynamic content
                position: "relative",
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                borderRadius: "10px",
              }}
            >
              {user?.caIdStatus === 1 && (
                <GitHubForkRibbon color="green" position="left">
                  Verified & Trusted
                </GitHubForkRibbon>
              )}
              <CardHeader
                avatar={
                  <Avatar
                    src={
                      user.Photo !== "" && user.Photo !== null
                        ? user?.Photo
                        : UserPlaceHolder
                    }
                    alt={user.FirstName}
                  />
                }
                action={
                  <Dropdown
                    isOpen={openMenuId === user.Id}
                    toggle={() => toggleMenu(user.Id)}
                  >
                    <DropdownToggle tag="div">
                      {/* visible badge if phoneShow is 1 and dialExt is not 0 or null */}
                      <IconButton>
                        <Badge
                          color="error"
                          variant="dot"
                          overlap="circular"
                          size="small"
                          invisible={
                            user.phoneShow === 0 ||
                            (user.phoneShow === 1 &&
                              user.dialExt !== null &&
                              user.dialExt !== 0)
                          }
                        >
                          <CiMenuKebab size={20} />
                        </Badge>
                      </IconButton>
                    </DropdownToggle>
                    <DropdownMenu end>
                      <DropdownItem onClick={() => onEdit(user)}>
                        <Edit fontSize="small" /> Edit
                      </DropdownItem>
                      <DropdownItem onClick={() => onDelete(user)}>
                        <Delete fontSize="small" /> Delete
                      </DropdownItem>
                      <DropdownItem onClick={() => onCustomersGotten(user)}>
                        <PeopleAltOutlined fontSize="small" /> Customers Gotten
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                }
                title={truncateTitle(`${user.FirstName} ${user.LastName}`)}
                subheader={`Role: ${user.roleName}`}
              />
              <CardMedia
                component="img"
                height="140"
                image={
                  user.Photo !== "" && user.Photo !== null
                    ? user?.Photo
                    : UserPlaceHolder
                }
                alt={user.FirstName}
              />
              <CardContent>
                <Typography variant="body2" color="text.secondary">
                  <b>Email:</b> {user.Email}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  <b>Mobile No:</b> {user.MobileNo} <b>Ext:</b> {user.dialExt}
                </Typography>
                {/* show 60 character length only of BioData then ... */}
                <Typography variant="body2" color="text.secondary">
                  {/* <b>Bio:</b> {user.BioData} */}
                  <b>Bio:</b>{" "}
                  {user.BioData?.length > 60
                    ? user.BioData?.slice(0, 60) + "..."
                    : user.BioData}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  <b> User ID:</b> {user.Id}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <UpdateUserModal
        isOpen={openUpdateUserModal}
        closeModal={() => setOpenUpdateUserModal(false)}
        userInfo={userToUpdate}
      />
      <CustomerGottonModal
        open={openGottonModal}
        close={() => setOpenGottenModal(false)}
        user={selectedUser}
      />
      <div className="pagination pb-5 d-flex justify-content-center">
        <Button
          className="me-2"
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
        >
          Previous
        </Button>
        <span className="align-self-center">
          Page {currentPage} of {totalPages}
        </span>
        <Button
          className="ms-2"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default UserCard;
