import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IoMdSend } from "react-icons/io";
import { TiTick } from "react-icons/ti";
import { GetImageSentListCustomer } from "../../../components/Header/Data3";
import {
  setMessageText,
  setSelectedConversation,
  setSelectedFile,
  setSendingFile,
} from "../../../redux/slices/MsgSlice";
import { useSocket } from "../../../contexts/SocketContext";
import { sendMessage } from "../../../components/Header/Data";
import { toast } from "react-toastify";

const ImageContainer = ({ fetchMsgs, fetchAllMsg }) => {
  const [images, setImages] = useState(null);
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const { selectedConvo, messageText } = useSelector((state) => state.messages);
  const { selectedAd } = useSelector((state) => state.lcAds);
  const { sendNewMessage, onMessageReceived } = useSocket();
  const dispatch = useDispatch();
  const selectedFile = useSelector((state) => state.messages.selectedFile);
  const fetchImages = async () => {
    const images = await GetImageSentListCustomer(selectedConvo.Id);
    setImages(images);
  };
  useEffect(() => {
    fetchImages();
  }, [selectedConvo.Id]);
  console.log(images, "images");

  const handleFileSend = async (image) => {
    // dispatch(setSelectedFile(image));
    dispatch(setSendingFile(true));
    let messageForm;
    if (selectedConvo) {
      messageForm = {
        customerId:
          selectedConvo.customerId === user.UserID
            ? selectedConvo.createdBy
            : selectedConvo.customerId,
        postId: selectedConvo.postId,
        postType: 4, // message 1 , requestQuote 2 , offer my service 3 , Attachment 4
        parentId:
          selectedConvo.parentId === 0
            ? selectedConvo.Id
            : selectedConvo.parentId,
        Description: selectedFile?.name || image?.image_url.split("/").pop(),
        messageStatus: 8,
        createdBy: user.UserID,
        roleId: user.RoleId,
        ownerId: selectedConvo.ownerId,
        userName: user.UserName,
        Email: user.Email,
        serviceNeed: "need",
        needName: selectedConvo.postName || "Text message",
        Photo: image?.image_url,
        imageSend: 1,
        imageId: image?.id,
      };
      const response = await sendMessage(messageForm);
      if (response.Data) {
        console.log(response.Data, "chatMessage");
        let data = response.Data;
        if (data.parentId == 0) {
          dispatch(setSelectedConversation(data));
        }
        // else {
        //   fetchMsgs();
        // }
        sendNewMessage(response.Data);
        dispatch(setMessageText(""));
        toast.success("Message Sent");
      }
      //   fetchMsgs();
      //   dispatch(setSelectedFile(null));
      dispatch(setSendingFile(false));
    } else {
      messageForm = {
        customerId: selectedAd?.createdBy,
        postId: selectedAd?.Id,
        postType: 4, // message 1 or requestQuote 2 or offer my service 3
        Description: selectedFile?.name || image?.image_url.split("/").pop(),
        messageStatus: 8,
        createdBy: user.UserID,
        roleId: user.RoleId,
        ownerId: selectedAd ? selectedAd.createdBy : `0`,
        userName: user.UserName,
        Email: user.Email,
        serviceNeed: "need",
        needName: selectedAd?.Title || "Text message",
        Photo: image?.image_url,
        imageSend: 1,
        imageId: image?.id,
      };
      const response = await sendMessage(messageForm);

      if (response.Data) {
        console.log(response.Data, "enteredMessage");
        let data = response.Data;
        if (data.parentId == 0) {
          dispatch(setSelectedConversation(data));
        }
        // else {
        //   fetchMsgs();
        // }
        sendNewMessage(response.Data);
        dispatch(setMessageText(""));
        toast.success("Message Sent");
      }
      //   dispatch(setSelectedFile(null));
      dispatch(setSendingFile(false));
    }
    setTimeout(() => {
      fetchImages();
      fetchMsgs();
      fetchAllMsg();
    }, 1000);
  };
  return (
    <div
      style={{
        width: "100%",
        // position: "absolute",
        overflowX: "scroll",
        overflowY: "hidden",
        scrollBehavior: "smooth",
        scrollbarWidth: "none",
        msOverflowStyle: "none",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          flexWrap: "noWrap",
          // width: "100%",
          overflowX: "scroll",
          overflowY: "hidden",
          scrollBehavior: "smooth",
          //   scrollbarWidth: "none",
          msOverflowStyle: "none",
        }}
        className="row"
      >
        {images &&
          images.map((image) => (
            <div key={image?.id}>
              {image?.image_type === 0 && (
                <div
                  className="inbox-template-card my-1 col"
                  style={{ alignItems: "center", justifyContent: "center" }}
                >
                  <img
                    src={image?.image_url}
                    alt="image"
                    style={{ flex: 1, width: "80%" }}
                  />
                  <div className="my-1">
                    {image?.sent_status === 0 ? (
                      <button
                        className="btn"
                        style={{ backgroundColor: "#4aaad3" }}
                        onClick={(e) => {
                          handleFileSend(image);
                        }}
                      >
                        <span style={{ color: "white" }}>
                          <IoMdSend />
                          Send
                        </span>
                      </button>
                    ) : (
                      <button
                        className="btn"
                        style={{
                          backgroundColor: "green",
                          cursor: "auto",
                        }}
                      >
                        <span style={{ color: "white" }}>
                          <TiTick />
                          Sent
                        </span>
                      </button>
                    )}
                  </div>
                </div>
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

export default ImageContainer;
