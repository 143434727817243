import React, { useState, useEffect } from "react";
import { Row, Col, Button, Input, Form, FormGroup, Label } from "reactstrap";
import { FaPlusCircle, FaTrashAlt } from "react-icons/fa";
import Select from "react-select";
import { fetchUserDetails } from "../../../components/Header/Data";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { SavePayPalAccountDetail } from "../../../components/Header/Data2";
import {
  setApplyAll,
  setMandate,
  setPayPalEmail,
  setTimeSlots,
} from "../../../redux/slices/ServiceInfo";

const SetAvailability = ({ email, setEmail, userDetails, fetchData }) => {
  const dispatch = useDispatch();
  const serviceInfo = useSelector((state) => state.serviceInfo);
  const [slots, setSlots] = useState(
    serviceInfo.timeSlots || [{ days: [], from: "", to: "" }]
  );
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;

  const navigate = useNavigate();
  const weekdays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const saveEmail = async () => {
    if (!email) {
      toast.error("please enter PayPal ID");
      return;
    }
    let formData = {
      // id: user.UserID,
      paypalEmail: email,
    };
    const response = await SavePayPalAccountDetail(formData, user.UserID);
    dispatch(setPayPalEmail(email));
    toast.success("PayPal Id Updated");

    fetchData();
  };

  function convertTo12HourFormat(time24) {
    if (!time24) {
      return "";
    }
    const [hour24, minute] = time24.split(":").map(Number);
    if (isNaN(hour24) || isNaN(minute)) {
      return "";
    }
    const period = hour24 >= 12 ? "PM" : "AM";
    const hour12 = (hour24 % 12 || 12).toString().padStart(2, "0"); // Added padStart
    return `${hour12}:${minute.toString().padStart(2, "0")} ${period}`;
  }

  const handleAddSlot = () => {
    const updatedSlots = [...slots, { days: [], from: "", to: "" }];
    const isNewSlotIncomplete = updatedSlots
      .slice(0, -1)
      .some(
        (slot) => slot.days.length === 0 || slot.from === "" || slot.to === ""
      );
    if (isNewSlotIncomplete) {
      toast.error("Please select avaialble days");
      return;
    }
    setSlots(updatedSlots);
    const isTimeSlotsFilled = updatedSlots.every(
      (slot) => slot.days.length > 0 && slot.from !== "" && slot.to !== ""
    );
    dispatch(setMandate(!isTimeSlotsFilled));
    console.log(!!isTimeSlotsFilled, "testMandate-handleAddSlot");
  };

  const handleRemoveSlot = (index) => {
    const updatedSlots = [...slots];
    updatedSlots.splice(index, 1);
    setSlots(updatedSlots);
    const isTimeSlotsFilled = updatedSlots.every(
      (slot) => slot.days.length > 0 && slot.from !== "" && slot.to !== ""
    );
    dispatch(setMandate(!isTimeSlotsFilled));
    console.log(!!isTimeSlotsFilled, "testMandate-handleRemoveSlot");
  };

  const handleChange = (index, field, value) => {
    const updatedSlots = [...slots];
    if (field === "days") {
      updatedSlots[index] = { ...updatedSlots[index], days: value };
    } else {
      updatedSlots[index] = { ...updatedSlots[index], [field]: value };
    }
    setSlots(updatedSlots);

    // Check if there are any time slots with empty fields
    const isTimeSlotsFilled = updatedSlots.every(
      (slot) => slot.days.length > 0 && slot.from !== "" && slot.to !== ""
    );
    dispatch(setMandate(!isTimeSlotsFilled));
    console.log(!!isTimeSlotsFilled, "testMandate-handleChange");
  };

  useEffect(() => {
    console.log(slots, "slots");
    dispatch(setTimeSlots(slots));
  }, [slots]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleApplyAll = () => {
    dispatch(setApplyAll(!serviceInfo.ApplyAll));
  };
  return (
    <Col>
      <Form>
        <Row className="form-container p-4 mt-4">
          <h6 className="pb-3">
            Set Available Days <span style={{ color: "red" }}>*</span>{" "}
          </h6>
          <Row
            style={{
              display: "flex",
              justifyContent: "space-around",
              margin: "2px",
            }}
          >
            <Col sm={3} xs={12} md={3} lg={3} className="d-none d-lg-block ">
              Days<b className="text-danger ">*</b>
            </Col>
            <Col sm={3} xs={12} md={3} lg={3} className="d-none d-lg-block">
              From<b className="text-danger ">*</b>
            </Col>
            <Col sm={3} xs={12} md={3} lg={3} className="d-none d-lg-block">
              To<b className="text-danger">*</b>
            </Col>
            <Col
              sm={3}
              xs={12}
              md={3}
              lg={3}
              className="d-none d-lg-block"
            ></Col>
          </Row>

          {slots.map((slot, index) => (
            <FormGroup
              key={index}
              className="row"
              style={{
                margin: "2px",
                justifyContent: "space-around",
                paddingTop: "10px",
              }}
            >
              <Col sm={3} xs={12} md={3} lg={3}>
                <p className="d-block d-lg-none text-dark">
                  Days <b className="text-danger">*</b>
                </p>
                {index === slots.length - 1 ? (
                  <Select
                    isMulti={true}
                    options={weekdays.map((day) => ({
                      value: day,
                      label: day,
                    }))}
                    value={
                      slot.days
                        ? slot.days.map((selectedDay) => ({
                            value: selectedDay,
                            label: selectedDay,
                          }))
                        : []
                    }
                    onChange={(selectedOptions) =>
                      handleChange(
                        index,
                        "days",
                        selectedOptions
                          ? selectedOptions.map((option) => option.value)
                          : []
                      )
                    }
                    menuIsOpen={menuIsOpen}
                    onFocus={() => setMenuIsOpen(true)} // Open the menu when focused
                    onBlur={() => setMenuIsOpen(false)}
                  />
                ) : (
                  <span style={{ fontWeight: "bold" }}>
                    {slot.days.join(", ")}
                  </span>
                )}
              </Col>
              <Col sm={3} xs={12} md={3} lg={3}>
                <p className="d-block d-lg-none text-dark">
                  From <b className="text-danger">*</b>
                </p>
                {index === slots.length - 1 ? (
                  <Input
                    type="time"
                    value={slot.from}
                    onChange={(e) =>
                      handleChange(index, "from", e.target.value)
                    }
                    style={{ width: "100%" }}
                  />
                ) : (
                  <span style={{ fontWeight: "bold" }}>
                    {" "}
                    {convertTo12HourFormat(slot.from)}
                  </span>
                )}
              </Col>
              <Col sm={3} xs={12} md={3} lg={3}>
                <p className="d-block d-lg-none text-dark">
                  To <b className="text-danger">*</b>
                </p>
                {index === slots.length - 1 ? (
                  <Input
                    type="time"
                    value={slot.to}
                    onChange={(e) => handleChange(index, "to", e.target.value)}
                    style={{ width: "100%" }}
                  />
                ) : (
                  <span style={{ fontWeight: "bold" }}>
                    {convertTo12HourFormat(slot.to)}
                  </span>
                )}
              </Col>
              <Col sm={3} xs={12} md={3} lg={3} className="p-1 mt-3 mt-lg-0">
                {index === slots.length - 1 && (
                  <span
                    onClick={handleAddSlot}
                    className="wc_add-timeslot d-flex align-items-center justify-content-center"
                  >
                    Click To Add Timeslot &nbsp;
                    <FaPlusCircle />
                  </span>
                )}
                {index !== slots.length - 1 && (
                  <span
                    onClick={() => handleRemoveSlot(index)}
                    className="d-flex justify-content-center align-items-center mt-0"
                  >
                    <FaTrashAlt />
                  </span>
                )}
              </Col>
            </FormGroup>
          ))}

          <FormGroup>
            <Input
              type="checkbox"
              onChange={handleApplyAll}
              checked={serviceInfo?.ApplyAll}
            />{" "}
            <span> Apply to all my Services</span>
          </FormGroup>
        </Row>
        {/* <Row className="form-container p-5 mt-4">
          <h6>Set unavailable days</h6>
          <span className="mt-3">
            Go to{" "}
            <Link to={"/dashboardpage/calendar"} style={{ color: "blue" }}>
              Your Calendar
            </Link>{" "}
            to set up the days in which you will not be available during the
            year
          </span>
        </Row> */}
        <Row className="form-container p-5 mt-4">
          <h6>Cashout</h6>
          <span className="pt-2">PayPal Connect</span>
          <span style={{ fontSize: "small", paddingTop: "5px" }}>
            <p> Connect your PayPal account to publish your listing</p>
          </span>
          <div
            className="m-4 p-4"
            style={{
              backgroundColor: "#FFF3CD",
              borderRadius: "5px",
              color: "#856404",
            }}
          >
            You must connect to PayPal in order to sell on the marketplace.Your
            listings will not be visible until you connect.
          </div>
          {/* <FormGroup>
            <Button type="button" className="btn btn-primary">
              Connect with PayPal
            </Button>
          </FormGroup> */}
          <FormGroup>
            {serviceInfo.paypalEmail ? (
              <h5 className="text-center mt-4">
                {" "}
                PayPal ID :{" "}
                <span className="fw-bold text-black">
                  {serviceInfo.paypalEmail}
                </span>
              </h5>
            ) : (
              <div
                className="d-flex mt-4 align-items-center justisy-content-center"
                style={{ marginLeft: "18%" }}
              >
                <h5> Enter PayPal ID: </h5>
                <Input
                  type="email"
                  id="email"
                  value={email}
                  onChange={handleEmailChange}
                  //onChange={handleEmailChange}
                  required
                  style={{ width: "38%", marginLeft: "10px" }}
                  className="mx-2"
                />
                <button
                  type="button"
                  className="btn btn-primary mt-0"
                  onClick={saveEmail}
                >
                  Save
                </button>
              </div>
            )}
          </FormGroup>
        </Row>
      </Form>
    </Col>
  );
};

export default SetAvailability;
