import React, { useEffect, useState } from "react";
import { Row, Col, FormGroup, Input, Button } from "reactstrap";
import { FaPlusCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import uploadToS3 from "../../../utils/awsS3Upload";
import { setUploadedImages } from "../../../redux/slices/ServiceInfo";

const ServiceImages = () => {
  const dispatch = useDispatch();
  const serviceInfo = useSelector((state) => state.serviceInfo);
  const [highlightedImages, setHighlightedImages] = useState(
    Array(30).fill(null)
  );
  const [showAll, setShowAll] = useState(false);

  const handleImageChange = async (index, event) => {
    const files = event.target.files;
    let folder = "service/";
    if (files.length > 0) {
      const imageFile = files[0];
      const imageUrl = await uploadToS3(imageFile, folder);
      const newImages = [...highlightedImages];
      newImages[index] = imageUrl;
      setHighlightedImages(newImages);
      dispatch(setUploadedImages(JSON.stringify(newImages.filter(Boolean))));
    }
  };

  useEffect(() => {
    const jsonPhoto =
      serviceInfo?.uploadedImages &&
      typeof serviceInfo?.uploadedImages === "string" &&
      JSON.parse(serviceInfo?.uploadedImages);
    if (jsonPhoto?.length > 0) {
      const imagesToAdd = Array(30 - jsonPhoto.length).fill(null);
      setHighlightedImages(jsonPhoto.concat(imagesToAdd));
    } else {
      setHighlightedImages(Array(30).fill(null));
    }
  }, [serviceInfo.uploadedImages]);

  const handleDragStart = (index, event) => {
    event.dataTransfer.setData("index", index.toString());
  };

  const handleDrop = (index, event) => {
    event.preventDefault();
    const dragIndex = parseInt(event.dataTransfer.getData("index"));
    if (
      dragIndex === index ||
      dragIndex < 0 ||
      dragIndex >= highlightedImages.length
    )
      return;
    const newImages = [...highlightedImages];
    const [draggedImage] = newImages.splice(dragIndex, 1);
    newImages.splice(index, 0, draggedImage);
    setHighlightedImages(newImages);
    dispatch(setUploadedImages(JSON.stringify(newImages.filter(Boolean))));
  };

  return (
    <>
      <Row className="mb-5">
        <h6>
          Highlighted Sample Work <span style={{ color: "red" }}>*</span>
        </h6>
        <span style={{ fontSize: "small", padding: "10px" }}>
          Add images of your listing. The first one will be the Cover image and
          will be displayed in the Browse page. You can reorder these after
          uploading.
        </span>
        {(showAll ? highlightedImages : highlightedImages.slice(0, 4)).map(
          (image, index) => (
            <Col xs={12} sm={12} md={6} lg={3} key={index}>
              <div>
                <FormGroup>
                  <Input
                    type="file"
                    id={`imageUpload${index + 1}`}
                    onChange={(e) => handleImageChange(index, e)}
                    style={{ display: "none" }}
                    name="images"
                  />
                </FormGroup>
                <div
                  className="service-image-box"
                  onClick={() =>
                    document.getElementById(`imageUpload${index + 1}`).click()
                  }
                  onDragStart={(e) => handleDragStart(index, e)}
                  onDragOver={(e) => e.preventDefault()}
                  onDrop={(e) => handleDrop(index, e)}
                  draggable
                >
                  {image ? (
                    <img
                      src={image}
                      alt={`Highlighted Image ${index + 1}`}
                      className="uploaded-image image-fluid rounded"
                    />
                  ) : (
                    <FaPlusCircle className="upload-icon" />
                  )}
                </div>
                {index === 0 && (
                  <p className="text-primary text-center mt-2">Cover Image</p>
                )}
              </div>
            </Col>
          )
        )}
      </Row>
      <div className="text-end">
        <Button
          color="link"
          className="text-primary"
          onClick={() => setShowAll(!showAll)}
        >
          {showAll ? "Show Less" : "Show More"}
        </Button>
      </div>
    </>
  );
};

export default ServiceImages;
